import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Link from '@mui/material/Link';
import ReactGA from 'react-ga4';
import { AuthContext } from '@/containers/AuthProvider';
import { useLocation } from 'react-router-dom';

const StyledDialogContent = styled(DialogContent)`
  min-height: 100px;
`;

const StyledDialogActions = styled(DialogActions)`
  padding-bottom: 20px;
`;

export function cookieConsentCookieExists() {
  return document.cookie.match(/^(.*;)?\s*CookieConsent\s*=\s*[^;]+(.*)?$/);
}

export function ignoredConsentPage(pathname) {
  const ignoredRoutes = ['/', '/sign-in', '/sign-up', '/terms', '/privacy', '/forgot-password'];

  return ignoredRoutes.indexOf(pathname) !== -1;
}

const CookieConsent = () => {
  const { isAuthenticated } = useContext(AuthContext);
  // If the cookie does not exist, or the user is not signed in open the modal
  // Also don't show if this is the landing page
  const { pathname } = useLocation();
  // Track when a user has closed the modal once in this session so we don't
  // show it on every route change
  const [closedInSession, setClosedInSession] = useState(false);
  const modalUnseen = !(isAuthenticated || closedInSession || cookieConsentCookieExists());
  const modalDisplayable = modalUnseen && !ignoredConsentPage(pathname);
  const [modalOpen, setModalOpen] = useState(modalDisplayable);

  const handleCloseModal = () => {
    setClosedInSession(true);
    setModalOpen(false);
  };

  const handleAcceptCookies = () => {
    document.cookie = 'CookieConsent=true; path=/; max-age=31536000';

    if (process.env.VITE_GOOGLE_ANALYTICS_KEY) {
      ReactGA.initialize(process.env.VITE_GOOGLE_ANALYTICS_KEY);
    }

    setClosedInSession(true);
    setModalOpen(false);
  };

  useEffect(() => {
    // Don't trigger GA on the landing page
    if (modalDisplayable) {
      setModalOpen(true);
    }
  }, [pathname]);

  return (
    <Dialog open={modalOpen} onClose={handleCloseModal} aria-labelledby="cookie-dialog-title">
      <StyledDialogContent>
        This site uses cookies as outlined in our
        {' '}
        <Link href="privacy">Privacy Policy</Link>
        .

        By pressing Accept, you agree to allow us use cookies to gather analytics information and customize your site experience.
      </StyledDialogContent>
      <StyledDialogActions>
        <Button variant="outlined" onClick={handleCloseModal} data-cy="cancel-button">
          Cancel
        </Button>
        <Button variant="outlined" onClick={handleAcceptCookies} data-cy="accept-button" color="primary">
          Accept
        </Button>
      </StyledDialogActions>
    </Dialog>
  );
};

export default CookieConsent;
