import React from 'react';

import { SelectOneCard } from '@/components/Practice/Cards/Common';
import { VERB_TAKES_PREPOSITION_MAP } from '@/helpers/reason_map';

const VerbPrepositionCard = (props) => (
  <SelectOneCard
    {...props}
    showTranslation
    type="Verbs with Prepositions"
    options={['a', 'de', 'por', 'con', 'en', 'que']}
    reasonMap={VERB_TAKES_PREPOSITION_MAP}
  />
);

export default VerbPrepositionCard;
