import axios from 'axios';

const TENSES = [
  "Present",
  "Preterite",
  "Imperfect",
  "Future",
  "Conditional",
  "Present Perfect",
  "Past Perfect",
  "Future Perfect",
  "Conditional Perfect",
  "Present Subjunctive",
  "Imperfect Subjunctive",
  "Past Perfect Subjunctive",
  "Present Perfect Subjunctive",
  "Imperative",
  "Negative Imperative",
  "Infinitive",
  "Gerund",
  "Past Participle"
]

export const axiosInstance = axios.create();

// axiosInstance.interceptors.request.use(
//   config => {
//     config.headers.Authorization = `Bearer ${window.localStorage.getItem('auth')}`;
//     return config;
//   },
//   error => Promise.reject(error)
// );

export function readAuthToken() {
  return JSON.parse(window.sessionStorage.getItem('user'));
}

export const convertFromServerTenseArray = (tenseArray) => {
  return tenseArray.map((value) => {
    return TENSES[parseInt(value)];
  });
}

export function clearAuthToken() {
  return window.sessionStorage.setItem('user', '');
}

export function setAuthToken(data) {
  return window.sessionStorage.setItem('user', JSON.stringify(data));
}

export const convertToServerTenseArray= (tenseArray) => {
  return tenseArray.map((value) => {
    return TENSES.indexOf(value);
  });
}
