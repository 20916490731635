import React from 'react';
import styled from 'styled-components';

import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';

const StyledSkeleton = styled(Skeleton)`
  margin-bottom: 12px;
`;

const StyledGrid = styled(Grid)`
  margin-top: 10px;
`;

const ListWrapper = ({ data, children, ...rest }) => {
  return (
    <StyledGrid container spacing={4} justifyContent="center" alignItems="flex-start" {...rest}>
      {data === null && (
        <Grid item xs={12}>
          <StyledSkeleton variant="rectangular" animation="wave" />
          <StyledSkeleton variant="rectangular" animation="wave" />
          <StyledSkeleton variant="rectangular" animation="wave" />
        </Grid>
      )}
      {data !== null && children}
    </StyledGrid>
  );
};

export default ListWrapper;
