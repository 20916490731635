import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import VerbClozeTitle from './VerbClozeTitle';
import Skeleton from '@mui/material/Skeleton';

import { PracticeCard } from '@/components/Practice/Cards/Elements';
import VerbClozeForm from './VerbClozeForm';


const ContentWrapper = styled.div`
  min-height: 205px;
`;

const VerbClozeCard = ({
  conjugation,
  disabled,
  fetchNext,
  formData,
  handleSubmit,
  infinitive,
  loading,
  sentence,
  setFormData,
  showFailure,
  showSuccess,
  showSkeleton,
  verbCloze
}) => {
  return (
    <PracticeCard
      question={verbCloze}
      sentence={sentence}
      showFailure={showFailure}
      showSuccess={showSuccess}
      id={verbCloze.id}
      type="VerbCloze"
      titleNode={<VerbClozeTitle loading={showSkeleton} tense={conjugation.tense} questionId={verbCloze.id} />}
      loading={!showSkeleton && loading}
      initialLoadPending={showSkeleton}
    >
      <ContentWrapper>
        {showSkeleton ? (
          <div>
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </div>
          ) : <VerbClozeForm
          handleSubmit={handleSubmit}
          sentence={sentence}
          infinitive={infinitive}
          conjugation={conjugation}
          verbCloze={verbCloze}
          fetchNext={fetchNext}
          setFormData={setFormData}
          formData={formData}
          disabled={disabled}
        />}
      </ContentWrapper>
    </PracticeCard>
  )
}

VerbClozeCard.propTypes = {
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  showSkeleton: PropTypes.bool
};

VerbClozeCard.defaultProps = {
  disabled: false,
  formData: '',
  showSkeleton: false
}

export default VerbClozeCard;
