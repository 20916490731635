import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#37474f',
      light: '#62727b',
      dark: '#102027',
    },
    secondary: {
      main: '#26c6da',
      light: '#6ff9ff',
      dark: '#0095a8',
    },
  },
  typography: {
    useNextVariants: true,
  },
  shape: {
    // borderRadius: 0
  },
});

export default theme;
