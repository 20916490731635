import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import TextField from '@mui/material/TextField';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import { splitSentenceAtBlanks } from '@/helpers/practice_helper';
import { verbFormDisplay } from '@/helpers/conjugation_conversions';
import {
  AccentMenu, NextButton, StyledForm, TranslationButton,
} from '@/components/Practice/Cards/Elements';

const StyledInputField = styled(({ inputWidth, ...rest }) => <TextField {...rest} />)`
  margin: 0px 6px;

  input {
    width: ${(props) => (props.inputWidth)}px;
    padding: 1px 0 3px;
    margin: 0px 6px;

    &:read-only {
      color: gray;
    }
  }

  p {
    text-align: center;
  }
`;

const QuestionWrapper = styled.div`
  width: 90%;
`;

// Don't show the button at all
const DesktopNextButton = styled(NextButton)`
  display: ${(props) => (props.visible ? 'block' : 'none')};
  margin: 0 auto;
  margin-top:  ${(props) => (props.text === 'Submit' ? '6px' : '42px')};
`;

const SentenceText = styled.div`
  display: inline-block;
  margin: 0 5px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
`;

const AdditionalFields = styled.div`
  padding-bottom: 8px;
`;

const StyledTranslation = styled(TranslationButton)`
  ${({ theme }) => `
    padding-bottom: 18px;

    ${theme.breakpoints.down('xs')} {
      padding-top: 8px;
    }
  `}
`;

const CenteredAccentMenu = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: center;
`;

const VerbForm = styled(StyledForm)`
  display: block;
  height: 100%;

  ${({ theme }) => `
    ${theme.breakpoints.down('sm')} {
      padding: 20px 0;
    }
  `}
`;

const MobileNextButton = styled(NextButton)`
  margin: 0 auto;
  position: relative;
  bottom: 15px;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  ${({ theme }) => `
    ${theme.breakpoints.down('xs')} {
      min-height: 350px;
      padding: 0 10px;
    }
  `}
`;

// TODO: Pass input field as a prop, and avoid passing formdata, setform data, practicedisabled, handlesubmit etc
const VerbClozeForm = ({
  conjugation,
  disabled,
  fetchNext,
  formData,
  handleSubmit,
  infinitive,
  sentence,
  setFormData,
  verbCloze,
}) => {
  const theme = useTheme();
  const inputField = React.useRef(null);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // When we remove the disabled class, refocus the input
  useEffect(() => {
    if (!disabled && inputField.current) {
      inputField.current.focus();
    }
  }, [disabled]);

  const splitSentence = splitSentenceAtBlanks({ ...verbCloze, ...sentence });
  const form = verbFormDisplay(conjugation.form, verbCloze.displayText)?.toLowerCase();
  let placeholder = infinitive.name;
  let inputWidth = (placeholder.length + 7) * 6.5;

  if (form) {
    placeholder += ` (${form})`;
    inputWidth = placeholder.length * 6.5;
  }

  const addAccent = (accentedCharacter) => {
    setFormData(`${formData}${accentedCharacter}`);
    inputField.current.focus();
  };

  return (
    <Wrapper>
      <VerbForm noValidate onSubmit={handleSubmit}>
        <QuestionWrapper>
          <SentenceText>
            {splitSentence[0]}
            <StyledInputField
              InputProps={{
                inputProps: { 'data-cy': 'card-input' },
                inputRef: inputField,
              }}
              color="secondary"
              disabled={disabled}
              margin="normal"
              helperText={placeholder}
              inputWidth={inputWidth}
              variant="standard"
              required
              id="answer"
              name="answer"
              autoComplete="off"
              autoFocus
              value={formData || ''}
              onChange={(e) => setFormData(e.target.value)}
            />
            {splitSentence[2]}
          </SentenceText>
        </QuestionWrapper>
        <AdditionalFields>
          <StyledTranslation translation={sentence.translation} />
          {!disabled && <CenteredAccentMenu><AccentMenu updateValue={addAccent} /></CenteredAccentMenu>}
          {!isMobile && (
            <DesktopNextButton
              visible
              disabled={false}
              fetchNext={disabled ? fetchNext : handleSubmit}
              text={disabled ? 'Next' : 'Submit'}
            />
          )}
        </AdditionalFields>
      </VerbForm>
      {isMobile && (
        <MobileNextButton
          visible
          disabled={false}
          fetchNext={disabled ? fetchNext : handleSubmit}
          text={disabled ? 'Next' : 'Submit'}
        />
      )}
    </Wrapper>
  );
}

VerbClozeForm.propTypes = {
  disabled: PropTypes.bool,
};

VerbClozeForm.defaultProps = {
  disabled: false,
  formData: '',
};

export default VerbClozeForm;
