import React from 'react';

import Avatar from '@mui/material/Avatar';
import styled from 'styled-components';

import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';

import { axiosInstance } from '@/helpers/api_helper';
import { useLocation, useNavigate } from 'react-router-dom';

const ErrorText = styled(Typography)`
  padding-top: 12px;
  color: crimson;
`;

const StyledPaper = styled('div')`
  ${({ theme }) => `
    margin-top: ${theme.spacing(8)};
  `}

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Confirm = () => {
  const [errorMessage, setErrorMessage] = React.useState('');
  const location = useLocation();
  const navigate = useNavigate();

  function confirmUser() {
    const token = location;

    axiosInstance.get(
      `/api/v1/users/confirmation?confirmation_token=${token}`
    ).then((response) => {
      navigate('/sign-in');
    }, (error) => {
      const message = error.response.data?.errors?.[0]?.title || 'Something went wrong!';
      setErrorMessage(message);
    });
  }

  React.useEffect(() => { confirmUser(); }, []);

  return (
    <StyledPaper>
      <Avatar>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Confirming...
      </Typography>
      {errorMessage
        && (
        <ErrorText component="h4">
          {errorMessage}
        </ErrorText>
)}
    </StyledPaper>
  );
};

export default Confirm;
