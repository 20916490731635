import React, { useContext, useEffect } from 'react';

import { Route, Routes, useLocation } from 'react-router-dom';
import { cookieConsentCookieExists } from '@/containers/CookieConsent';

import {
  QuestionByIdPage,
  ParaPorPage,
  PreteriteImperfectPage,
  SerEstarPage,
  SubjunctiveIndicativePage,
  VerbClozePage,
  VerbPrepositionPage,
  DirectIndirectObjectPage,
} from '@/containers/Practice/';
import VerbClozeFormContainer from '@/components/Practice/VerbClozeForm/VerbClozeFormContainer';

import { AuthenticatedDashboardContainer, UnAuthenticatedDashboard } from '@/components/Dashboard';
import {
  Landing, Privacy, Tos, Faq, Changelog, VocabularyIndex, VocabularyPage, StaticLayout
} from '@/components/Static';
import { AuthContext } from '@/containers/AuthProvider';

import ReactGA from 'react-ga4';

import Profile from '@/containers/Session/Profile';
import SignIn from '@/containers/Session/SignIn';
import { SignUp, ForgotPassword, ResetPassword } from '@/components/Session';
import { ignoredConsentPage } from './CookieConsent';

const RouterConfig = () => {
  const { isAuthenticated } = useContext(AuthContext);
  const { pathname } = useLocation();

  useEffect(() => {
    // Don't trigger GA on the landing page
    if (!ignoredConsentPage(pathname) && (isAuthenticated || cookieConsentCookieExists())) {
      ReactGA.send({ hitType: 'pageview', page: pathname });
    }
  }, [pathname]);

  return (
    <Routes>
      <Route path="/sign-in" element={<SignIn />} />
      <Route path="/users/auth/*" element={<SignIn />} />
      <Route path="/sign-up" element={<SignUp />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password/:token" element={<ResetPassword />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/profile/:start" element={<Profile />} />

      <Route path="/para-por" element={<ParaPorPage />} />
      <Route path="/ser-estar" element={<SerEstarPage />} />
      <Route path="/preterite-imperfect" element={<PreteriteImperfectPage />} />
      <Route path="/subjunctive-indicative" element={<SubjunctiveIndicativePage />} />
      <Route path="/direct-indirect-object" element={<DirectIndirectObjectPage />} />
      <Route path="/verb-preposition" element={<VerbPrepositionPage />} />

      <Route path="/question/:id" element={<QuestionByIdPage />} />

      <Route path="/verb-cloze/practice" element={<VerbClozePage />} />
      <Route path="/verb-cloze" element={<VerbClozeFormContainer />} />

      <Route element={<StaticLayout />}>
        <Route path="/terms" element={<Tos />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/changelog" element={<Changelog />} />
        <Route path="/vocabulary" element={<VocabularyIndex />} />
        <Route path="/vocabulary/:show" element={<VocabularyPage />} />
      </Route>

      {/*
        <Route path="/confirmation-sent" element={ConfirmationMessage} />
        <Route path="/confirm" element={Confirm} />
      */}

      {isAuthenticated && <Route path="/" element={<AuthenticatedDashboardContainer />} />}
      {!isAuthenticated && <Route path="/" element={<Landing />} />}

      {isAuthenticated && <Route path="/dashboard" element={<AuthenticatedDashboardContainer />} />}
      {!isAuthenticated && <Route path="/dashboard" element={<UnAuthenticatedDashboard />} />}
    </Routes>
  );
};

export default RouterConfig;
