import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from '@mui/material/Button';
import TranslateIcon from '@mui/icons-material/Translate';

const StyledButton = styled(Button)`
  min-width: 36px;
`;

const Translation = styled.span`
  padding-left: 6px;
  visibility: ${(props) => (props.$visible ? 'visible' : 'hidden')};
`;

const TranslationButton = ({ translation, ...rest }) => {
  const [clicked, setClicked] = useState(false);

  const handleClick = () => {
    setClicked((prevValue) => !prevValue);
  };

  useEffect(() => {
    setClicked(false);
  }, [translation]);

  return (
    <div {...rest}>
      <StyledButton data-cy="translation-button" onClick={handleClick}>
        <TranslateIcon />
      </StyledButton>
      <Translation $visible={clicked} data-cy="translation-text">{translation}</Translation>
    </div>
  );
};

TranslationButton.propTypes = {
  translation: PropTypes.string.isRequired,
};

export default TranslationButton;
