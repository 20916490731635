import React from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components';

import { Button, ButtonGroup } from '@mui/material';

const StyledButton = styled(Button)`
  text-transform: none;
`;

const accentedCharacters = ['ñ', 'á', 'é', 'í', 'ó', 'ú', 'ü'];

const AccentMenu = ({ updateValue, ...otherProps }) => {
  const buttonOptions = accentedCharacters.map(character => (
    <StyledButton key={character} onClick={() => updateValue(character)}>{character}</StyledButton>
  ));

  return (
    <ButtonGroup color="primary" aria-label="outlined primary button group" {...otherProps}>
      {buttonOptions}
    </ButtonGroup>
  );
};

AccentMenu.propTypes = {
  setValue: PropTypes.func,
};

export default AccentMenu;
