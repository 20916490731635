export const RUBY = '#ab003c';
export const PLUM = '#6d1b7b';
export const PURPLE = '#4527a0';
export const NAVY = '#283593';
export const AQUA = '#1769aa';
export const GREEN = '#2e7d32';
export const BLUE_GRAY = '#455a64';
export const TOMATO = '#bf360c';
export const DEEP_BLUE = '#01579b';
export const TEAL = '#00796b';

export const tenseColors = {
  'present': RUBY,
  'preterite': PLUM,
  'imperfect': PURPLE,
  'future': NAVY,
  'conditional': AQUA,
  'present_subjunctive': GREEN,
  'imperfect_subjunctive': BLUE_GRAY,
  'imperative': TOMATO,
  'negative_imperative': DEEP_BLUE,
  'gerund': PURPLE
}
