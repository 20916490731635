import React from 'react';
import Grid from '@mui/material/Grid';

import { axiosInstance } from '@/helpers/api_helper';
import ForgotPasswordForm from './ForgotPasswordForm';

const ForgotPassword = () => {
  const [error, setError] = React.useState('');
  const [success, setSuccess] = React.useState('');

  function handleResetPassword(email, password, password_confirmation) {
    axiosInstance.post(
      `/api/v1/users/password`,
       { user: { email: email } }
    ).then((response) => {
      setSuccess('An email is on its way!');
    }, (error) => {
      setError('Something went wrong!');
    });
  }

  return (
    <Grid container justifyContent="center">
      <ForgotPasswordForm handleResetPassword={handleResetPassword} successMessage={success} errorMessage={error} />
    </Grid>
  )
}

export default ForgotPassword;
