import React from 'react';

import PropTypes from 'prop-types'
import styled from 'styled-components';

import ListWrapper from '../List/ListWrapper';
import BookmarkItem from './BookmarkItem';
import Typography from '@mui/material/Typography';

const StyledListWrapper = styled(({ empty, ...rest }) => <ListWrapper {...rest} />)`
  ${({ empty }) => empty && `
    height: 100%;
    align-items: center;
  `}
`;

const BookmarkList = ({ bookmarks, onBookmarkClick }) => {
  const itemKeys = bookmarks ? bookmarks['meta']['ids'] : [];

  return (
    <StyledListWrapper data={bookmarks} empty={itemKeys.length === 0 && bookmarks !== null}>
      {itemKeys.map((key) => {
        const bookmark = bookmarks['bookmarks'][key];
        const questionId = bookmark['relationships']['answerable']['data']['id'];
        const questionType = bookmark['relationships']['answerable']['data']['type'];
        const sentenceId = bookmark['relationships']['sentence']['data']['id'];

        const question = bookmarks[questionType][questionId]['attributes'];
        const sentence = bookmarks['sentence'][sentenceId]['attributes'];

        return <BookmarkItem key={key} onBookmarkClick={onBookmarkClick} bookmark={bookmark['attributes']} sentence={sentence} question={question} />
      })}
      {itemKeys.length === 0 && (
        <Typography>You don't have any bookmarks!</Typography>
      )}
    </StyledListWrapper>
  );
};

BookmarkList.propTypes = {
  bookmarks: PropTypes.object,
  onBookmarkClick: PropTypes.func.isRequired,
};

export default BookmarkList;

