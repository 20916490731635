import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types';

import CardHeader from './CardHeader';

import { AuthContext } from '@/containers/AuthProvider';
import { axiosInstance } from '@/helpers/api_helper';
import { SnackbarContext } from '@/containers/SnackbarProvider';

const CardHeaderContainer = ({
  bookmarkId,
  loading,
  questionType,
  questionId,
  titleNode,
}) => {
  const [newBookmarkId, setNewBookmarkId] = React.useState(undefined);
  const currentBookmarkId = newBookmarkId === undefined ? bookmarkId : newBookmarkId;
  const { user, isAuthenticated } = React.useContext(AuthContext);
  const { snackbarOpen, setSnackbarOpen, setAlertState } = useContext(SnackbarContext);

  const handleBookmarkClick = (e) => {
    // TODO: This is missing coverage
    const type = questionType === 'VerbCloze' ? 'SentenceConjugation' : 'Question';

    if (questionId !== undefined) {
      if (currentBookmarkId === null) {
        axiosInstance.post('/api/v1/users/bookmarks', { type: type, question_id: questionId }).then((response) => {
          const newId = response.data.data.id;
          setNewBookmarkId(newId);
        }).catch((error) => {
          const message = error.response.data.error || 'Something went wrong!';

          setAlertState({ message, severity: "error" });
          setSnackbarOpen(true);
        });
      } else {
        axiosInstance.delete(`/api/v1/users/bookmarks/${currentBookmarkId}`).then((response) => {
          setNewBookmarkId(null);
        }).catch((error) => {
          setNewBookmarkId(null);

          if (process.env.NODE_ENV === 'development') {
            console.log(error);
          }
        });
      }
    };
  }

  // Reset the bookmark information when the card changes
  useEffect(() => {
    setNewBookmarkId(undefined);
  }, [questionId]);

  return (
    <CardHeader
      bookmarked={currentBookmarkId !== null}
      questionId={questionId}
      questionType={questionType}
      onBookmarkClick={handleBookmarkClick}
      titleNode={titleNode}
      loading={loading}
    />
  );
};

CardHeaderContainer.propTypes = {
  bookmarkId: PropTypes.number,
  loading: PropTypes.bool.isRequired,
  questionId: PropTypes.number,
  questionType: PropTypes.string
};

CardHeaderContainer.defaultProps = {
  bookmarkId: null
};

export default CardHeaderContainer;
