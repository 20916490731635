import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from '@mui/material/Button';

const StyledButton = styled(({ visible, ...rest }) => <Button {...rest} />)`
  visibility: ${props => props.visible ? 'visible' : 'hidden' };
`;

const NextButton = ({ visible, fetchNext, text, ...rest }) => {
  const [clicked, setClicked] = useState(false);

  const handleClick = (e) => {
    setClicked(true);
    fetchNext(e);
  };

  useEffect(() => {
    setClicked(false);
  }, [visible]);

  return (
    <StyledButton
      color="primary"
      variant="outlined"
      visible={visible}
      disabled={clicked}
      onClick={handleClick}
      data-cy="next-button"
      {...rest}
    >
      {text}
    </StyledButton>
  );
};

NextButton.defaultProps = {
  text: 'Next',
};

NextButton.propTypes = {
  visible: PropTypes.bool.isRequired,
  fetchNext: PropTypes.func.isRequired,
  text: PropTypes.string,
};

export default NextButton;
