import React from 'react';
import PropTypes from 'prop-types';

const VerbFormDisplay = ({ form }) => (
  <>
    {form === 'yo' && 'First person singular'}
    {form === 'tu' && 'Second person singular'}
    {form === 'ella' && 'Third person singular'}
    {form === 'nosotros' && 'First person plural'}
    {form === 'vosotros' && 'Second person plural'}
    {form === 'ustedes' && 'Third person plural'}
  </>
);

VerbFormDisplay.propTypes = {
  form: PropTypes.string,
};

export default VerbFormDisplay;
