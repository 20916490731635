import React, { useState } from 'react';

export const SnackbarContext = React.createContext();

const SnackbarProvider = ({ children }) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [alertState, setAlertState] = useState({ message: '', severity: '' });

  const defaultContext = {
    snackbarOpen,
    setSnackbarOpen,
    alertState,
    setAlertState,
  };

  return (
    <SnackbarContext.Provider value={defaultContext}>
      {children}
    </SnackbarContext.Provider>
  );
}

export default SnackbarProvider;
