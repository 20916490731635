import React, { useState } from 'react';

import { axiosInstance } from '@/helpers/api_helper';

import BookmarkList from './BookmarkList';
import normalize from 'json-api-normalizer';

const ListContainer = () => {
  const [data, setData] = useState(null);

  function removeBookmark(id) {
    axiosInstance.delete(`/api/v1/users/bookmarks/${id}`).catch((error) => {
      if (process.env.MODE=== 'development') {
        console.log(error);
      }
    });

    setData(() => {
      const newData = { ...data };
      delete newData.bookmarks[id];

      const sortedIds = Object.keys(newData.bookmarks);
      Object.assign(newData, { meta: { ids: sortedIds }});

      return newData;
    });
  }

  function fetchData() {
    const request = axiosInstance.get('api/v1/users/bookmarks');

    return request.then((response) => {
      // Apparently you can't store sorted orders with this stupid library
      // So store and stash the sorted keys
      const keys = Object.keys(response.data.data);
      const normalizedData = normalize(response.data);

      const sortedIds = response.data.data.map(record => parseInt(record.id));

      Object.assign(normalizedData, { meta: { ids: sortedIds }});
      setData(normalizedData);
    });
  }

  React.useEffect(() => {
    fetchData();
  }, []);

  return <BookmarkList onBookmarkClick={removeBookmark} bookmarks={data} />
};

export default ListContainer;
