import React from 'react';
import styled from 'styled-components';

import Typography from '@mui/material/Typography';

import { format } from 'date-fns'
import { splitSentenceAtBlanks } from '@/helpers/practice_helper';
import { ColoredDoneIcon, ColoredCloseIcon } from '@/components/Practice/Cards/Elements/StatusIcons';
import ListItem from '../List/ListItem';

const CorrectAnswerWrapper = styled.div`
  padding-top: 6px;
`;

const HistoryItem = ({ item, sentence, question }) => {
  const { answer, correct, createdAt } = item;
  const splitSentence = splitSentenceAtBlanks({ ...question, ...sentence });
  const correctAnswer = splitSentence[1];

  return (
    <ListItem
      answer={answer}
      sentence={sentence}
      question={question}
      PreSentenceContent={<Typography variant="caption">{format(new Date(createdAt), 'MM/dd/yyyy h:mmaaaa')}</Typography>}
      PostTranslationContent={!correct && <CorrectAnswerWrapper>Correct Answer: { correctAnswer }</CorrectAnswerWrapper>}
      IconContent={(
        <>
          {correct && <ColoredDoneIcon />}
          {!correct && <ColoredCloseIcon />}
        </>
      )}
    />
  );
};

export default HistoryItem;
