import React from 'react';

import Avatar from '@mui/material/Avatar';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';

import styled from 'styled-components';

import { Link } from "react-router-dom";

const StyledPaper = styled('div')`
  ${({ theme }) => `
    margin-top: ${theme.spacing(8)};
  `}

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MessageText = styled(Typography)`
  padding-top: 16px;
`;

const StyledAvatar = styled(Avatar)`
  ${({ theme }) => `
    margin: ${theme.spacing(1)};
    background-color: ${theme.palette.secondary.main};
  `}
`;

const ConfirmationMessage = () => {
  return (
    <StyledPaper>
      <StyledAvatar>
        <LockOutlinedIcon />
      </StyledAvatar>
      <Typography component="h1" variant="h5">
        You're signed up!
      </Typography>
      <MessageText component="h4">
        Look for a confirmation email in your inbox and <Link to="/sign-in" variant="body2">{"Sign In"}</Link>.
      </MessageText>
    </StyledPaper>
  )
};

export default ConfirmationMessage;
