import React from 'react';
import PropTypes from 'prop-types';

import CheckboxGroup from '@/components/Shared/CheckboxGroup';
import Grid from '@mui/material/Grid';

const TENSES = [
  'Conditional',
  'Future',
  'Imperfect',
  'Present',
  'Preterite',
  'Gerund',
];

const COMMANDS = [
  'Imperative',
  'Negative Imperative',
];

// const PERFECT_TENSES = [
//   'Conditional Perfect',
//   'Future Perfect',
//   'Past Perfect',
//   'Present Perfect'
// ];

const SUBJUNCTIVE_TENSES = [
  'Present Subjunctive',
  'Imperfect Subjunctive',
  // 'Past Perfect Subjunctive',
  // 'Present Perfect Subjunctive'
];

const TenseCheckboxes = ({ disabled, disabledOptions, formData, setFormData }) => {
  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <CheckboxGroup
          allDisabled={disabled}
          label="Simple Tenses"
          values={TENSES}
          formData={formData}
          setFormData={setFormData}
          disabledOptions={disabledOptions}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <CheckboxGroup
          allDisabled={disabled}
          label="Commands"
          values={COMMANDS}
          formData={formData}
          setFormData={setFormData}
          disabledOptions={disabledOptions}
        />

        <CheckboxGroup
          allDisabled={disabled}
          label="Subjunctive Tenses"
          values={SUBJUNCTIVE_TENSES}
          formData={formData}
          setFormData={setFormData}
          disabledOptions={disabledOptions}
        />
      </Grid>
    </Grid>
  );
};

TenseCheckboxes.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
};

export default TenseCheckboxes;
