import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Typography from '@mui/material/Typography';

import { tenseDisplay } from '@/helpers/conjugation_conversions';
import { tenseColors } from '@/helpers/colorMap';
import { AuthContext } from '@/containers/AuthProvider';

const StyledTitle = styled(Typography)`
  color: ${props => (tenseColors[props.tense])};
  text-transform: uppercase;
`;

const VerbClozeTitle = ({
  loading,
  questionId,
  tense
}) => {
  const { isAdmin, isAuthenticated } = React.useContext(AuthContext);

  return (
    <StyledTitle data-cy="card-title" tense={tense}>
      {!loading && (
        <>
          {tenseDisplay(tense)}
          {isAdmin && ` - ${questionId}`}
        </>
      )}
    </StyledTitle>
  )
}

VerbClozeTitle.propTypes = {
  tense: PropTypes.string,
  questionId: PropTypes.number
};

export default VerbClozeTitle;
