import React, { useContext } from 'react'

import { default as MaterialSnackbar } from '@mui/material/Snackbar';

import Alert from '@mui/material/Alert';
import { SnackbarContext } from '@/containers/SnackbarProvider';

const Snackbar = () => {
  const {
    snackbarOpen,
    setSnackbarOpen,
    alertState: { message, severity },
  } = useContext(SnackbarContext);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  return (
    <MaterialSnackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      open={snackbarOpen}
      autoHideDuration={3200}
      onClose={handleClose}
      data-cy="snackbar"
    >
      {snackbarOpen ? (
        <Alert onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      ) : null }
    </MaterialSnackbar>
  );
}

export default Snackbar;
