// Given a sentence, and a position where the blank starts, as well as it's length,
// Returns an array of 3 items; the sentence up until the blank,
// the blank, and the sentence after the blank.
// Will place punctuation attached to the blank word in the array index before or
// after depending on where it is attached to the blank.
//
// Ie, "He went to, the store?", 2, 1
// Returns ["He went", "to", ", the store?"]
export const splitSentenceAtBlanks = ({ text, blankStart, blankLength }) => {
  const splitText = text.split(" "); 
  const output = [];

  output[0] = splitText.slice(0, blankStart).join(" ");;
  output[1] = splitText.slice(blankStart, blankStart + blankLength).join(" ");
  output[2] = splitText.slice(blankStart + blankLength).join(" ");

  if (/^[¡\.,¿]/.test(output[1])) {
    // If the blank started with punctuation, remove it from the blank
    // and append it to the end of the preceeding string

    output[0] += ` ${output[1][0]}`;
    output[1] = output[1].slice(1);
  } else if ((/[?\.,!]$/.test(output[1]))) {
    // If the blank ended with punctuation, append it to the beginning
    // of the following text, and remove it from the blank

    const lastElementIndex = output[1].length - 1;

    output[2] = `${output[1][lastElementIndex]} ${output[2]}`;
    output[1] = output[1].slice(0, lastElementIndex);
  }

  return output;
}
