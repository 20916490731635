import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';

import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import { splitSentenceAtBlanks } from '@/helpers/practice_helper';
import { NextButton, PracticeCard, StyledForm, TranslationButton } from '@/components/Practice/Cards/Elements';
import Skeleton from '@mui/material/Skeleton';
import SelectOneCardForm from './SelectOneCardForm';

const ContentWrapper = styled.div`
  min-height: 143px;
`;

const SelectOneCard = ({
  answer,
  disabled,
  fetchNext,
  handleSubmit,
  options,
  question,
  reasonMap,
  sentence,
  showFailure,
  showSuccess,
  showTranslation,
  showSkeleton,
  type,
  loading,
}) => {
  return (
    <PracticeCard
      id={question.id}
      showFailure={showFailure}
      showSuccess={showSuccess}
      question={question}
      sentence={sentence}
      type={type}
      loading={loading}
      initialLoadPending={showSkeleton}
    >
      <ContentWrapper>
        {showSkeleton ? (
          <div>
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </div>
          ) : <SelectOneCardForm
            question={question}
            sentence={sentence}
            disabled={disabled}
            handleSubmit={handleSubmit}
            showTranslation={showTranslation}
            answer={answer}
            options={options}
            reasonMap={reasonMap}
            fetchNext={fetchNext}
          />
        }
      </ContentWrapper>
    </PracticeCard>
  )
}

SelectOneCard.propTypes = {
  answer: PropTypes.string,
  disabled: PropTypes.bool,
  fetchNext: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  options: PropTypes.array,
  question: PropTypes.object,
  reasonMap: PropTypes.object,
  sentence: PropTypes.object,
  showFailure: PropTypes.bool,
  showSuccess: PropTypes.bool,
  showTranslation: PropTypes.bool,
  type: PropTypes.string,
  loading: PropTypes.bool,
  showSkeleton: PropTypes.bool
};

SelectOneCard.defaultProps = {
  disabled: false,
  reasonMap: {},
  showTranslation: false,
  showSkeleton: false
};

export default SelectOneCard;
