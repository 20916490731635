import React from 'react';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';

const UserDataForm = ({ formData, setFormData }) => {
  return (
    <>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email Address"
        name="email"
        autoComplete="email"
        disabled
        autoFocus
        value={formData.email || ''}
        onChange={(e) => setFormData({ ...formData, email: e.target.value })}
      />

      <FormControlLabel
        control={(
          <Checkbox
            color="secondary"
            checked={formData.include_vosotros}
            onChange={(e) => setFormData({ ...formData, include_vosotros: e.target.checked })}
            value="include_vosotros"
          />
        )}
        label="Include Vosotros"
      />
      <br />
      <FormControlLabel
        control={(
          <Checkbox
            color="secondary"
            checked={formData.include_usted}
            onChange={(e) => setFormData({ ...formData, include_usted: e.target.checked })}
            value="include_usted"
          />
        )}
        label="Include Usted"
      />
      <br />
      <FormControlLabel
        control={(
          <Checkbox
            color="secondary"
            checked={formData.include_vos}
            onChange={(e) => setFormData({ ...formData, include_vos: e.target.checked })}
            value="include_vos"
          />
        )}
        label="Include Vos"
      />
      <br />
      <FormControlLabel
        control={(
          <Checkbox
            color="secondary"
            checked={formData.ignore_accents}
            onChange={(e) => setFormData({ ...formData, ignore_accents: e.target.checked })}
            value="ignore_accents"
          />
        )}
        label="Ignore Missing Accents"
      />
    </>
  );
};

UserDataForm.propTypes = {
};

UserDataForm.defaultProps = {
  formData: {},
};

export default UserDataForm;
