import React from 'react';
import styled from 'styled-components';

import HistoryItem from './HistoryItem';
import ListWrapper from '../List/ListWrapper';

const StyledListWrapper = styled(ListWrapper)`
  padding-bottom: 20px;
`;

const HistoryList = ({ history }) => {
  const itemKeys = history ? history.meta.ids : [];

  return (
    <StyledListWrapper data={history}>
      {itemKeys.map((key) => {
        const item = history.historyItems[key];
        const questionId = item.relationships.answerable.data.id;
        const questionType = item.relationships.answerable.data.type;
        const sentenceId = item.relationships.sentence.data.id;

        const question = history[questionType][questionId].attributes;
        const sentence = history.sentence[sentenceId].attributes;

        return <HistoryItem key={key} item={item.attributes} sentence={sentence} question={question} />;
      })}
    </StyledListWrapper>
  );
};

export default HistoryList;
