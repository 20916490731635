import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';


const ButtonStyles = css`
  ${props => props.active && css`
    animation: fade .6s linear;
  `}

  @keyframes fade {
    0% {
      opacity: 0.2;
      zIndex: 500;
    }
    80% {
      opacity: 1;
    }
    100% {
      opacity: 0.2;
      zIndex: 0;
    }
  }
  height: 120px;
  width: 120px;
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
`;

export const ColoredCloseIcon = styled(CloseIcon)`
  color: #ca2f2fb0;
`;

export const ColoredDoneIcon = styled(DoneIcon)`
  color: #008000b0;
`;

const AnimatedCloseIcon = styled(({ active, ...rest }) => <ColoredCloseIcon {...rest} />)`
  ${ButtonStyles}
`;

const AnimatedDoneIcon = styled(({ active, ...rest }) => <ColoredDoneIcon {...rest} />)`
  ${ButtonStyles}
`;

const StatusIcons = ({ showSuccess, showFailure }) => {
  return (
    <>
      <AnimatedDoneIcon data-cy="done-icon" active={showSuccess} />
      <AnimatedCloseIcon data-cy="close-icon" active={showFailure} />
    </>
  );
}

StatusIcons.propTypes = {
  showFailure: PropTypes.bool,
  showSuccess: PropTypes.bool,
};

StatusIcons.defaultProps = {
  showFailure: false,
  showSuccess: false,
};

export default StatusIcons;
