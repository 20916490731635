import React from 'react';
import styled from 'styled-components';

import AppBar from '@mui/material/AppBar';
import AccountCircle from '@mui/icons-material/AccountCircle';

import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Toolbar from '@mui/material/Toolbar';
import { axiosInstance } from '@/helpers/api_helper';
import { AuthContext } from '@/containers/AuthProvider';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import LeftNavButton from './LeftNavButton';
import PageTitle from './PageTitle';

const StyledToolbar = styled(Toolbar)`
  justify-content: space-between;
`;

const StyledLink = styled(Link)`
  ${({ theme }) => `
    color: ${theme.palette.text.primary};
    text-decoration: none;
  `}
`;

const StyledAppBar = styled(AppBar)`
  ${({ theme }) => `
    .MuiToolbar-regular {
      min-height: 52px;
    }

    ${theme.breakpoints.up('md')} {
      .MuiToolbar-regular {
        min-height: 58px;
      }
    }
  `}
`;

const StyledAccountCircle = styled(({ loggedIn, ...rest }) => <AccountCircle {...rest} />)`
  ${({ loggedIn, theme }) => `
    border-radius: 100%;
    box-shadow: 0px 0px 2px 1px ${loggedIn ? theme.palette.secondary.light : 'transparent'};
  `}
`;

const NavBar = ({ match, toggleDrawer }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { setUser, isAuthenticated, isAdmin } = React.useContext(AuthContext);

  function handleMenu(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const handleSignOut = () => {
    handleClose();
    axiosInstance.delete('/api/v1/users/sign_out');
    setUser({});

    return navigate('/sign-in');
  };

  return (
    <StyledAppBar position="static">
      <StyledToolbar>
        <LeftNavButton loggedIn={isAuthenticated} toggleDrawer={toggleDrawer} />
        <Hidden mdUp>
          <PageTitle pathname={pathname} />
        </Hidden>
        <div>
          <IconButton
            aria-label="Account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
            data-cy="user-icon"
            id="account-icon"
          >
            <StyledAccountCircle loggedIn={isAuthenticated} />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={open}
            onClose={handleClose}
          >
            {isAuthenticated && (
            <div>
              <StyledLink to="/profile">
                <MenuItem data-cy="profile-link" onClick={handleClose}>Profile</MenuItem>
              </StyledLink>
              <StyledLink to="/faq">
                <MenuItem data-cy="faq" onClick={handleClose}>FAQ</MenuItem>
              </StyledLink>
              {isAdmin && (
                <StyledLink as="a" href="/admin">
                  <MenuItem data-cy="admin-link" onClick={handleClose}>Admin</MenuItem>
                </StyledLink>
              )}
              <MenuItem data-cy="sign-out-button" onClick={handleSignOut}>Sign Out</MenuItem>
            </div>
            )}
            {!isAuthenticated && (
            <div>
              <StyledLink to="/sign-in">
                <MenuItem data-cy="sign-in-link" onClick={handleClose}>Sign In</MenuItem>
              </StyledLink>
              <StyledLink to="/sign-up">
                <MenuItem data-cy="sign-up-link" onClick={handleClose}>Sign Up</MenuItem>
              </StyledLink>
              <StyledLink to="/faq">
                <MenuItem data-cy="faq" onClick={handleClose}>FAQ</MenuItem>
              </StyledLink>
            </div>
            )}
          </Menu>
        </div>
      </StyledToolbar>
    </StyledAppBar>
  );
};

export default NavBar;
