import React from 'react';

import { SelectOneCard } from '@/components/Practice/Cards/Common';
import { PARA_POR_MAP } from '@/helpers/reason_map';

const ParaPorCard = (props) => (
  <SelectOneCard
    {...props}
    type="Para/Por"
    showTranslation
    options={['para', 'por']}
    reasonMap={PARA_POR_MAP}
  />
);

export default ParaPorCard;
