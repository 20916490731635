import React from 'react';
import Grid from '@mui/material/Grid';

import { axiosInstance } from '@/helpers/api_helper';
import { AuthContext } from '@/containers/AuthProvider';
import ResetPasswordForm from './ResetPasswordForm';
import { useNavigate } from 'react-router-dom';

const ResetPassword = () => {
  const [error, setError] = React.useState('');
  const { setUser } = React.useContext(AuthContext);
  const navigate = useNavigate();

  function handleChangePassword(token, password, password_confirmation) {
    axiosInstance.patch(
      `/api/v1/users/password`,
       { user: { reset_password_token: token, password, password_confirmation } }
    ).then((response) => {
      setUser({ role: response.data.role });
      navigate('/');
    }, (error) => {
      let firstFieldName = Object.keys(error.response.data.errors)[0];
      let displayedName = firstFieldName && firstFieldName.charAt(0).toUpperCase() + firstFieldName.substring(1);

      setError(`${displayedName} ${error.response.data.errors[firstFieldName][0]}.`);
    });
  }

  return (
    <Grid container justifyContent="center">
      <ResetPasswordForm handleChangePassword={handleChangePassword} errorMessage={error} />
    </Grid>
  )
}

export default ResetPassword;
