import React from 'react';

import { SelectOneCard } from '@/components/Practice/Cards/Common';
import { SUBJUNCTIVE_MAP } from '@/helpers/reason_map';

const SubjunctiveIndicativeCard = ({ question, ...props }) => (
  <SelectOneCard
    {...props}
    showTranslation
    question={question}
    type="Subjunctive/Indicative"
    options={question.options}
    reasonMap={SUBJUNCTIVE_MAP}
   />
)

export default SubjunctiveIndicativeCard;
