import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import ListItem from '../List/ListItem';

import IconButton from '@mui/material/IconButton';
import BookmarkIcon from '@mui/icons-material/Bookmark';

const BookmarkItem = ({ bookmark, onBookmarkClick, question, sentence }) => {
  const handleClick = (e) => {
    onBookmarkClick(bookmark.id);
  }

  return (
    <ListItem
      answer={question.answer}
      record={bookmark}
      sentence={sentence}
      question={question}
      IconContent={(
        <IconButton
          aria-label="Bookmark"
          onClick={handleClick}
          color="inherit"
          data-cy="bookmark-icon"
          data-bookmarked="true"
        >
          <BookmarkIcon />
        </IconButton>
      )}
    />
  );
};

BookmarkItem.propTypes = {
  bookmark: PropTypes.object.isRequired,
  onBookmarkClick: PropTypes.func.isRequired,
  question: PropTypes.object.isRequired,
  sentence: PropTypes.object.isRequired,
};

export default BookmarkItem;
