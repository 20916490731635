import React, { useRef } from 'react';

import { axiosInstance, readAuthToken, setAuthToken } from '@/helpers/api_helper';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';

import _isEmpty from 'lodash/isEmpty';

export const AuthContext = React.createContext();

const AuthProvider = ({ children }) => {
  const prevAuth = readAuthToken() || {};
  const [user, setUser] = React.useState(prevAuth);
  const lastUserId = useRef(user && user.id);
  const navigate = useNavigate();

  const isAuthenticated = !_isEmpty(user);
  const isAdmin = user && user?.role?.includes('admin');

  const errorInterceptor = (error) => {
    if (error.response.status === 401) {
      if (user) {
        navigate('/sign-in');
      }

      setUser({});
    }

    return Promise.reject(error);
  };

  // I am guessing this is used to update the store when we login/out
  React.useEffect(
    () => {
      if (isAuthenticated) {
        // If we JUST logged in, initialize GA
        if (lastUserId.current === undefined && process.env.VITE_GOOGLE_ANALYTICS_KEY) {
          ReactGA.initialize(process.env.VITE_GOOGLE_ANALYTICS_KEY);
          ReactGA.set({ user_id: user.id });
        }

        lastUserId.current = user.id;
        setAuthToken(user);
      } else {
        setAuthToken(null);
      }
    },
    [user],
  );

  React.useEffect(() => {
    axiosInstance.interceptors.response.use(null, errorInterceptor);
  }, []);

  const defaultContext = {
    user,
    setUser,
    isAuthenticated,
    isAdmin,
  };

  return (
    <AuthContext.Provider value={defaultContext}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
