import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Typography from '@mui/material/Typography';
import CountUp from 'react-countup';
import Grid from '@mui/material/Grid';

const CounterBlock = styled(Grid)`
  text-align: center;
`;

const CountNumber = styled(Typography)`
  padding-bottom: 10px;
`;

const Counter = ({ count, name }) => {
  return (
    <CounterBlock item xs={12} md={4}>
      <CountNumber variant="h3">
        <CountUp enableScrollSpy scrollSpyOnce duration={1} end={count} />
      </CountNumber>

      <Typography variant="h5">{name}</Typography>
    </CounterBlock>
  );
};

Counter.propTypes = {
  count: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
};

export default Counter;
