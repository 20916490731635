import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography';
import VerbFormDisplay from '@/components/Shared/VerbFormDisplay';
import { capitalize } from '@/helpers/text_helper';

const VerbCardSubheader = ({ tense = '', form = '' }) => (
  <Typography data-cy="verb-card-subheader">
    {capitalize(tense)} - <VerbFormDisplay form={form} />
  </Typography>
);

VerbCardSubheader.propTypes = {
  tense: PropTypes.string,
  form: PropTypes.string,
};

export default VerbCardSubheader;
