import React from 'react';
import styled from 'styled-components';

import Typography from '@mui/material/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBookDead, faCloudMoon, faGifts, faComments, faPeopleCarry, faStar,
} from '@fortawesome/free-solid-svg-icons';
import { faStars } from '@/stars';

const RoundedIcon = styled(FontAwesomeIcon)`
  ${({ theme }) => `
    // border: 1px solid ${theme.palette.text.secondary};
    color: ${theme.palette.text.primary};
    margin-right: 14px;
    min-width: 28px;
  `}
`;

const Image = styled.img`
  ${({ theme }) => `
    border: 1px solid ${theme.palette.text.secondary};
    width: 100%;
    max-width: 450px;
    margin: 25px 0;

    ${theme.breakpoints.up('sm')} {
      margin: 25px;
    }
  `}
`;

const ThemeList = styled.ul`
  ${({ theme }) => `
    list-style-type: none;
    margin: 0;
    padding: 0;
    padding-top: 20px;

    ${theme.breakpoints.up('sm')} {
      padding: 0;
      padding-left: 25px;
    }
  `}
`;

const StyledListItem = styled.li`
  &:not(:last-child) {
    margin-bottom: 20px;
  }

  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ThemeContainer = styled.div`
  ${({ theme }) => `
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    margin: 25px 0;

    ${theme.breakpoints.up('sm')} {
      flex-direction: row;
      align-items: center;
      margin-left: 65px;
    }
  `}
`;

const ListItem = ({ icon, text }) => {
  return (
    <StyledListItem>
      <RoundedIcon icon={icon} size="2x" />
      <Typography variant="body">{text}</Typography>
    </StyledListItem>
  );
};

const Themes = () => {
  return (
    <ThemeContainer>
      <Image src="/para_por.png" width="500px" />
      <ThemeList>
        <ListItem icon={faGifts} text="Para/Por" />
        {/* <ListItem icon={faCloudMoon} text="Imperfect/Preterite" /> */}
        <ListItem icon={faStars} text="Subjunctive/Indicative" />
        {/* <ListItem icon={faBookDead} text="Ser/Estar" /> */}
        <ListItem icon={faComments} text="Verbs that take a Preposition" />
        <ListItem icon={faPeopleCarry} text="Indirect/Direct object" />
      </ThemeList>
    </ThemeContainer>
  );
};

export default Themes;
