import React from 'react';

import Grid from '@mui/material/Grid';

import TextField from '@mui/material/TextField';
import { SessionFormWrapper, StyledLink, GoogleLink } from '@/components/Session/Shared';
import { SubmitButton } from '@/components/Shared';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

const SignInForm = ({ handleSignIn, errorMessage }) => {
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [rememberMe, setRememberMe] = React.useState(false);

  const handleSubmit = (evt) => {
    evt.preventDefault();
    handleSignIn(email, password, rememberMe);
  };

  return (
    <SessionFormWrapper errorMessage={errorMessage} title="Sign In" handleSubmit={handleSubmit}>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email Address"
        name="email"
        autoComplete="email"
        inputProps={{ 'data-cy': 'email' }}
        autoFocus
        onChange={(e) => setEmail(e.target.value)}
      />
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="password"
        label="Password"
        type="password"
        id="password"
        autoComplete="current-password"
        inputProps={{ 'data-cy': 'password' }}
        onChange={(e) => setPassword(e.target.value)}
      />
      <FormControlLabel
        control={(
          <Checkbox
            onChange={(e) => setRememberMe(e.target.checked)}
            checked={rememberMe}
            name="remember_me"
          />
        )}
        label="Remember Me"
      />
      <SubmitButton text="Sign In" />
      <GoogleLink text="Sign in with Google" />
      <hr width="50%" />
      <Grid container>
        <Grid item xs>
          <StyledLink to="/forgot-password">
            Forgot password?
          </StyledLink>
        </Grid>
        <Grid item>
          <StyledLink to="/sign-up">
            Don't have an account? Sign Up
          </StyledLink>
        </Grid>
      </Grid>
    </SessionFormWrapper>
  );
};

SignInForm.propTypes = {};

export default SignInForm;
