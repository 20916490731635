import React from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import styled from 'styled-components';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const HeaderRow = ({ heading }): JSX.Element => {
  return (
    <TableRow>
      <TableCell colSpan={3} component="th" scope="row" sx={{ fontWeight: '600', fontSize: '0.9rem' }}>
        {heading}
      </TableCell>
    </TableRow>
  );
};

const IncludedIcon = ({ included }): JSX.Element => {
  if (included) {
    return <CheckCircleIcon sx={{ color: 'green' }} />;
  }

  return <CancelIcon sx={{ color: 'crimson' }} />;
};

const DataRow = ({ title, anonymous, free }): JSX.Element => {
  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {title}
      </TableCell>
      <TableCell align="center"><IncludedIcon included={anonymous} /></TableCell>
      <TableCell align="center"><IncludedIcon included={free} /></TableCell>
    </TableRow>
  );
};

const StyledTable = styled(Table)`
  ${({ theme }) => `
    ${theme.breakpoints.up('sm')} {
      min-width: 650px;
    }
  `}
`;

const PricingTable = () => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Paper sx={{ marginTop: '28px' }}>
      <TableContainer>
        <StyledTable size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell align="center" sx={{ padding: '12px 0', fontWeight: '600', fontSize: '0.9rem' }}>Anonymous</TableCell>
              <TableCell align="center" sx={{ fontWeight: '600', fontSize: '0.9rem' }}>Signed Up</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <HeaderRow heading="Grammar Questions" />
            <DataRow title="Para/Por" anonymous free />
            <DataRow title="Subjunctive/Indicative" anonymous free />
            <DataRow title="Verbs that take Prepositions" anonymous free />
            <DataRow title="Direct/Indirect Object" anonymous free />
            <HeaderRow heading="Conjugation Practice" />
            <DataRow title="Practice hundreds of verbs in context" anonymous free />
            <DataRow title="Exclude uncommon verbs" free />
            <DataRow title="Choose exact tenses" free />
            <HeaderRow heading="Global Configuration" />
            <DataRow title="Bookmarking" free />
            <DataRow title="History" free />
            <DataRow title="Intelligent Question Serving" free />
            <DataRow title="Exclude vosotros/usted" free />
            <DataRow title="Exclude unknown conjugations" free />
          </TableBody>
        </StyledTable>
      </TableContainer>
    </Paper>
  );
};

export default PricingTable;
