import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { default as MuiCardHeader } from '@mui/material/CardHeader';

import IconButton from '@mui/material/IconButton';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import { AuthContext } from '@/containers/AuthProvider';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

const StyledBookmarkIcon = styled(BookmarkIcon)`
  font-size: 1.75rem;
  stroke: white;
`;

const StyledBookmarkBorderIcon = styled(BookmarkBorderIcon)`
  font-size: 1.75rem;
  stroke: white;
`;

const StyledCardHeader = styled(({ authenticated, ...rest }) => <MuiCardHeader {...rest} />)`
  margin-top: ${(props) => (props.authenticated ? '-8px' : 'inherit')};

  .MuiCardHeader-title {
    ${(props) => props.authenticated && css`
      position: relative;
      margin-left: 52px;
    `}
  }

  .MuiCardHeader-action {
    position: relative;
    margin-top: ${(props) => (props.authenticated ? 0 : 'inherit')};
  }
`;

function CardActions({
  authenticated,
  bookmarked,
  onBookmarkClick,
  loading,
  disabled,
}) {
  const Icon = bookmarked ? StyledBookmarkIcon : StyledBookmarkBorderIcon;

  const bookmarkButton = (
    <IconButton
      disabled={disabled || !authenticated}
      aria-label="Bookmark"
      onClick={onBookmarkClick}
      color="inherit"
      data-cy="bookmark-icon"
      data-bookmarked={bookmarked}
    >
      <Icon />
    </IconButton>
  );

  if (!authenticated) {
    return (
      <Tooltip title="Please sign up to enable bookmarking.">
        <span>{bookmarkButton}</span>
      </Tooltip>
    );
  }

  return bookmarkButton;
}

function CardHeader({
  bookmarked,
  loading,
  onBookmarkClick,
  questionType,
  questionId,
  titleNode,
}) {
  const { isAdmin, isAuthenticated } = React.useContext(AuthContext);
  const title = isAdmin ? `${questionType} - ${questionId}` : '';

  return (
    <StyledCardHeader
      authenticated={isAuthenticated}
      title={titleNode || (
      <Typography>
        {title}
&nbsp;
      </Typography>
      )}
      style={{ textAlign: 'center' }}
      action={<CardActions onBookmarkClick={onBookmarkClick} disabled={(questionId === undefined) || loading} authenticated={isAuthenticated} bookmarked={bookmarked} />}
    />
  );
}

CardHeader.propTypes = {
  bookmarked: PropTypes.bool,
  loading: PropTypes.bool,
  onBookmarkClick: PropTypes.func.isRequired,
  questionId: PropTypes.number,
  questionType: PropTypes.string,
  titleNode: PropTypes.node,
};

CardHeader.defaultProps = {
  bookmarked: false,
};

export default CardHeader;
