import React, { useState } from 'react';
import { AuthContext } from '@/containers/AuthProvider';
import { axiosInstance } from '@/helpers/api_helper';

import normalize from 'json-api-normalizer';

import AuthenticatedDashboard from './AuthenticatedDashboard';

const emptyStats = { totalSeen: 0 };
const emptyStatsList = {
  paraPor: emptyStats,
  subjunctiveIndicative: emptyStats,
  verbTakesPreposition: emptyStats,
  verbCloze: emptyStats,
  directIndirectObject: emptyStats,
};

const AuthenticatedDashboardContainer = () => {
  const [stats, setStats] = useState(emptyStatsList);
  const { user } = React.useContext(AuthContext);
  const lastTypePracticed = user.meta ? user.last_question_type_seen : null;

  function fetchData() {
    const request = axiosInstance.get('api/v1/users/stats');

    return request.then((response) => {
      const normalizedData = normalize(response.data);

      setStats(normalizedData.stats[user.id].attributes);
    });
  }

  const resetData = () => {
    const request = axiosInstance.delete('api/v1/users/stats');

    return request.then(() => {
      setStats({
        paraPor: {},
        subjunctiveIndicative: {},
        verbTakesPreposition: {},
        verbCloze: {},
        directIndirectObject: {},
      });
    });
  };

  React.useEffect(() => {
    // Don't bother to fetch if there's no stats
    if (lastTypePracticed) {
      fetchData();
    }
  }, []);

  return (
    <AuthenticatedDashboard
      resetData={resetData}
      stats={stats}
      lastTypePracticed={lastTypePracticed}
    />
  );
};

export default AuthenticatedDashboardContainer;
