import React from 'react';
import normalize from 'json-api-normalizer';
import Grid from '@mui/material/Grid';

import { usePageState, useDataFetchOnLoad } from './Hooks';
import { axiosInstance } from '@/helpers/api_helper';
import { AuthContext } from '@/containers/AuthProvider';
import { ParaPorCard, SerEstarCard, PreteriteImperfectCard, DirectIndirectObjectCard, VerbPrepositionCard, SubjunctiveIndicativeCard } from '@/components/Practice/Cards';
import { useMatch } from 'react-router-dom';
import styled from 'styled-components';

const Wrapper = styled(Grid)`
  display: flex;
  height: 100%;
  align-items: center;
`;

const QuestionByIdPage = () => {
  const { user } = React.useContext(AuthContext);
  const [pageState, dispatch] = usePageState();
  const match = useMatch('/question/:id');
  const id = match?.params?.id;
  const dataUrl = `/api/v1/questions/${id}`;
  const { normalizedData, loaded, loading } = useDataFetchOnLoad(dataUrl);
  let sentence = {};
  let question = {};

  function submitPracticeData(evt) {
    evt.preventDefault();
    dispatch({ type: 'updateAnswer', answer: evt.target.value });

    axiosInstance.post(`/api/v1/questions/${question.id}/answer`, { answer: evt.target.value, user_id: user.id })
      .then((response) => {
        dispatch({ type: 'correctAnswer' });
      }).catch((error) => {
        if (error.response.status !== 401) {
          let normalizedData = normalize(error.response.data);
          let question = Object.values(normalizedData.question)[0]['attributes'];

          // I don't think we need to update the state answer anymore given the two input displays
          dispatch({ type: 'incorrectAnswer', answer: question.answer })
        }
      });
  }

  if (loaded) {
    question = Object.values(normalizedData.question)[0]['attributes'];
    sentence = Object.values(normalizedData.sentence)[0]['attributes'];
  }

  let CardClass;

  if (loaded) {
    switch (question.type) {
      case 'Question::ParaPor':
        CardClass = ParaPorCard;
        break;
      case 'Question::DirectIndirectObject':
        CardClass = DirectIndirectObjectCard;
        break;
      case 'Question::SubjunctiveIndicative':
        CardClass = SubjunctiveIndicativeCard;
        break;
      case 'Question::VerbTakesPreposition':
        CardClass = VerbPrepositionCard;
        break;
      case 'Question::SerEstar':
        CardClass = SerEstarCard;
        break;
      case 'Question::PreteriteImperfect':
        CardClass = PreteriteImperfectCard;
        break;
      default:
        throw ('Unknown card type');
    }
  }

  const title = `Question - ${id}`;

  return (
    <Wrapper container justifyContent="center">
      {loaded ? (<CardClass
        disabled={pageState.practiceDisabled}
        handleSubmit={submitPracticeData}
        loading={loading}
        sentence={sentence}
        answer={pageState.answer}
        question={question}
        showSuccess={!!pageState.lastResponseStatus}
        showFailure={pageState.lastResponseStatus === false}
        showTranslation
        title={title} />) : null }
    </Wrapper>
  )
}

export default QuestionByIdPage;
