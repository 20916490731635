import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';

import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import { splitSentenceAtBlanks } from '@/helpers/practice_helper';
import {
  NextButton, StyledForm,
} from '@/components/Practice/Cards/Elements';

const StyledSelect = styled(({ inputWidth, ...rest }) => <Select {...rest} />)`
  width: ${(props) => (props.inputWidth)}px;
  margin: 0 6px;
  text-align: left;

  .MuiInputBase-input {
    padding: 5px 0 5px 2px;
  }

  &:first-child {
    margin-left: 0;
  }
`;

const SentenceText = styled.div`
  display: inline-block;
  font-size: 1rem;
  font-weight: 400;
  line-height: 33px;
  letter-spacing: 0.00938em;
`;

const ReasonWrapper = styled(Typography)`
  font-style: italic;
  min-height: 45px;
  opacity: .7;

  ${({ theme }) => `
    ${theme.breakpoints.down('xs')} {
      margin-bottom: 6px;
    }
  `}
`;

const AdditionalFields = styled.div`
  padding: 8px 0 0;
  height: 94px;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const StyledInput = styled(({ inputWidth, ...rest }) => <TextField {...rest} />)`
  max-width: ${(props) => (props.inputWidth)}px;
  padding-bottom: 1px;
  padding-top: 2px;
  margin: 0 6px 0;

  &:first-child {
    margin-left: 0;
  }

  input {
    padding-top: 3px;
    padding-bottom: 3px;
  }
`;

const StyledTranslation = styled.div`
  padding-top: 2px;
`;

const CenteredNextButton = styled(NextButton)`
  ${({ theme }) => `
    ${theme.breakpoints.up('sm')} {
      margin: 0 auto;
    }
  `}
`;

const clarificationString = (clarification) => {
  if (clarification === null || clarification === undefined) return '';

  return ` (${clarification})`;
};

function SelectOneCardForm({
  answer,
  disabled,
  fetchNext,
  handleSubmit,
  options,
  question,
  reasonMap,
  sentence,
  showTranslation,
}) {
  const splitSentence = splitSentenceAtBlanks({ ...question, ...sentence });
  const inputWidth = Math.max(...options.map((option) => option.length)) * 9 + 45;
  const sortedOptions = options.sort();

  return (
    <>
      <StyledForm noValidate onSubmit={handleSubmit}>
        <SentenceText disabled={disabled}>
          {splitSentence[0].length > 0 && (<span>{splitSentence[0]}</span>)}
          {!disabled && (
            <StyledSelect
              required
              variant="standard"
              id="answer"
              name="answer"
              autoComplete="off"
              color="secondary"
              autoFocus
              data-cy="card-input"
              value={answer}
              onChange={handleSubmit}
              inputWidth={inputWidth}
            >
              {sortedOptions.map((option) => (
                <MenuItem value={option} key={option}>{option}</MenuItem>
              ))}
            </StyledSelect>
          )}
          {disabled && (
            <StyledInput
              variant="standard"
              inputWidth={inputWidth}
              value={question.answer}
              disabled
            />
          )}
          <span>{splitSentence[2]}</span>
        </SentenceText>
        {(showTranslation) && (
          <StyledTranslation>{sentence.translation}</StyledTranslation>
        )}
      </StyledForm>
      {fetchNext && (
        <AdditionalFields data-cy="additional-fields">
          <ReasonWrapper data-cy="reason" variant="body2">
            {disabled && reasonMap[question.reason]}
            {disabled && question.explanation && ` (${question.explanation})`}
          </ReasonWrapper>
          <CenteredNextButton visible={disabled} fetchNext={fetchNext} />
        </AdditionalFields>
      )}
    </>
  );
}

SelectOneCardForm.propTypes = {
  answer: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  question: PropTypes.object.isRequired,
  sentence: PropTypes.object.isRequired,
  showTranslation: PropTypes.bool,
};

SelectOneCardForm.defaultProps = {
  disabled: false,
  showTranslation: false,
};

export default SelectOneCardForm;
