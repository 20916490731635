/* Handles the page state, answer last status,*/

import { useReducer } from 'react';

function reducer(state, action) {
  switch (action.type) {
    case 'correctAnswer':
      return {
        ...state,
        lastResponseStatus: true,
      };
    case 'incorrectAnswer':
      return {
        ...state,
        lastResponseStatus: false,
        practiceDisabled: true,
        answer: action.answer,
      };
    case 'pageClear':
      return {
        ...state,
        answer: '',
        lastResponseStatus: undefined,
        practiceDisabled: false,
      };
    case 'updateAnswer':
      return {
        ...state,
        lastResponseStatus: undefined,
        answer: action.answer,
      };
    case 'resetAnswer':
      return {
        ...state,
        answer: '',
      };
    case 'resetLastResponse':
      return {
        ...state,
        lastResponseStatus: undefined,
      };
    default:
      throw new Error('Unknown action type');
  }
}

const usePageState = () => {
  const [pageState, dispatch] = useReducer(reducer, {
    answer: '',
    practiceDisabled: false,
    lastResponseStatus: null,
  });

  return [pageState, dispatch];
};

export default usePageState;
