import React from 'react'
import PropTypes from 'prop-types'

import Grid from '@mui/material/Grid';

import TextField from '@mui/material/TextField';
import { SessionFormWrapper, StyledLink } from '@/components/Session/Shared';
import { SubmitButton } from '@/components/Shared';

const ForgotPasswordForm = ({ handleResetPassword, errorMessage, successMessage }) => {
  const [email, setEmail] = React.useState('');

  const handleSubmit = (evt) => {
    evt.preventDefault();
    handleResetPassword(email);
  }

  return (
    <SessionFormWrapper errorMessage={errorMessage} successMessage={successMessage} title="Reset Password" handleSubmit={handleSubmit}>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email"
        name="email"
        autoComplete="email"
        inputProps={{ 'data-cy': 'email' }}
        autoFocus
        onChange={e => setEmail(e.target.value)}
      />
      <SubmitButton text="Send me reset instructions" />
      <Grid container>
        <Grid item xs>
          <StyledLink to="/sign-in">
            Sign In
          </StyledLink>
        </Grid>
        <Grid item>
          <StyledLink to="/sign-up">
            Don't have an account? Sign Up
          </StyledLink>
        </Grid>
      </Grid>
    </SessionFormWrapper>
  )
};

ForgotPasswordForm.propTypes = {
  handleResetPassword: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
};

export default ForgotPasswordForm;
