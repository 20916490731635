import React from 'react';
import PropTypes from 'prop-types';

import InputAdornment from '@mui/material/InputAdornment';
import { verbFormDisplay } from '@/helpers/conjugation_conversions';

const VerbFormAdornment = ({ form }) => {
  const prefix = verbFormDisplay(form);

  return (
    <>{prefix && <InputAdornment position="start">{prefix}</InputAdornment>}</>
  );
};

VerbFormAdornment.propTypes = {
  form: PropTypes.string,
};

export default VerbFormAdornment;
