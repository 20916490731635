import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Link, useNavigate } from 'react-router-dom';

const Container = styled.div`
  text-align: center;
`;

const BackButton = styled(Button)`
  margin-top: 18px;
`;

const SubText = styled(Typography)`
  padding-top: 12px;
  padding-bottom: 12px;
`;

const NoQuestionsAvailable = ({ redirectTo }) => {
  const navigate = useNavigate();
  const handleClick = () => navigate(redirectTo);

  return (
    <Container data-cy="no-answers-text">
      <Typography variant="h5">
        Oh no, you've run out of cards!
      </Typography>
      <SubText variant="h6">
        Try relaxing your criteria as outlined in the <Link to="/faq">FAQ</Link>.
      </SubText>
      <BackButton
        data-cy="back-button"
        color="primary"
        variant="contained"
        onClick={handleClick}
      >
        Go back
      </BackButton>
    </Container>
  );
};

NoQuestionsAvailable.propTypes = {
  redirectTo: PropTypes.string.isRequired,
};

export default NoQuestionsAvailable;
