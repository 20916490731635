import React from 'react';

import { SelectOneCard } from '@/components/Practice/Cards/Common';

const DirectIndirectObjectCard = ({ question, ...props }) => (
  <SelectOneCard
    {...props}
    options={question.options}
    question={question}
    showTranslation
    type="Direct/Indirect Object"
  />
)

export default DirectIndirectObjectCard;
