import React from 'react';
import styled from 'styled-components';

import { Link } from 'react-router-dom';

const StyledLink = styled((props) => <Link variant="body2" {...props} />)`
  ${({ theme }) => `
    color: ${theme.palette.text.primary};
    text-decoration: underline;
  `}
`;

export default StyledLink;
