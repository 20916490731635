import React from 'react';
import styled from 'styled-components';

import Button from '@mui/material/Button';

const StyledButton = styled(Button)`
  ${({ theme }) => `
    width: 100%; // Fix IE 11 issue.
    margin: ${theme.spacing(3, 0, 2)};
  `}
`;

const SubmitButton = ({ text, children, ...rest }) => (
  <StyledButton
    type="submit"
    fullWidth
    variant="outlined"
    color="primary"
    data-cy="submit-button"
    {...rest}
  >
    {text}{children}
  </StyledButton>
);

SubmitButton.propTypes = {};

export default SubmitButton;
