import React from 'react';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';
import { useAllPrismicDocumentsByType } from '@prismicio/react';

import { PageTitle, Section } from './Common';

const ChangeGroup = styled.ul`
  margin-top: 8px;
`;

const Changelog = () => {
  const [documents] = useAllPrismicDocumentsByType('changelog', {
    orderings: [
      { field: "my.changelog.date", direction: "desc" }
    ]
  });

  return (
    <>
      <PageTitle variant="h4">Recent Updates</PageTitle>
      {documents && documents.map((document) => {
        return (
          <Section key={document.data.date}>
            <Typography variant="h5">{document.data.date}</Typography>
            <ChangeGroup>
              {document.data.change.map((change) => {
                return change.details.map((detail) => {
                  return (
                    <li key={detail.text}>
                      {detail.text}
                    </li>
                  );
                });
              })}
            </ChangeGroup>
          </Section>
        );
      })}
    </>
  );
};

export default Changelog;
