import React from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

export const StaticAppWrapper = styled.div`
  ${({ theme }) => `
    margin-right: 8px;
    margin-left: 8px;
    margin-top: 18px;

    ${theme.breakpoints.up('sm')} {
      margin-top: 34px;
      margin-right: 36px;
      margin-left: 36px;
    }
  `}
`;

const VocabularyIndex = () => {
  return (
    <StaticAppWrapper>
      <Outlet />
    </StaticAppWrapper>
  );
};

export default VocabularyIndex;
