import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { PracticeCard } from '@/components/Practice/Cards/Elements';
import CardHeader from '@mui/material/CardHeader';
import TextField from '@mui/material/TextField';

import VerbCardSubheader from './VerbCardSubheader';

import VerbFormAdornment from '@/components/Shared/VerbFormAdornment';

const StyledTextField = styled(TextField)`
  max-width: 350px;
  & .MuiInput-root: {
    textAlign: 'center';
    &:read-only: {
      color: 'gray';
    }
  },
`;

// TODO: Pass input field as a prop, and avoid passing formdata, setform data, practicedisabled, handlesubmit etc
const VerbCard = ({ id, infinitive, showFailure, showSuccess, formData, setFormData, disabled, handleSubmit, ...grammarInfoProps }) => {
  const inputField = React.useRef(null);

  // When we remove the disabled class, refocus the input
  React.useEffect(() => {
    if (!disabled && inputField.current) {
      inputField.current.focus();
    }
  }, [disabled]);

  return(
    <PracticeCard cardProps={{ 'data-cy': "verb-card" }} showFailure={showFailure} showSuccess={showSuccess} type="" title="">
      {infinitive && <CardHeader
        title={infinitive.name}
        style={{ textAlign: 'center' }}
        subheader={<VerbCardSubheader {...grammarInfoProps} />}
        />}
      <form noValidate onSubmit={handleSubmit}>
        <StyledTextField
          InputProps={{
            startAdornment: <VerbFormAdornment form={grammarInfoProps.form} />,
            inputProps: { 'data-cy': 'verb-card-input' },
            inputRef: inputField
          }}
          disabled={disabled}
          margin="normal"
          required
          fullWidth
          id="answer"
          name="answer"
          autoComplete='off'
          autoFocus
          value={formData.answer || ''}
          onChange={e => setFormData({...formData, answer: e.target.value})}
        />
      </form>
    </PracticeCard>
  )
}

VerbCard.propTypes = {
  infinitve: PropTypes.object,
  disabled: PropTypes.bool
};

VerbCard.defaultProps = {
  formData: {}
}

export default VerbCard;
