import React, { useState } from 'react';
import { axiosInstance } from '@/helpers/api_helper';

import normalize from 'json-api-normalizer';
import HistoryList from './HistoryList';

type HistoryContainerProps = {
  shouldFetch: boolean
}

const HistoryContainer = ({ shouldFetch }: HistoryContainerProps): JSX.Element => {
  const [history, setHistory] = useState(null);

  function fetchData() {
    const request = axiosInstance.get('api/v1/users/history_items');

    return request.then((response) => {
      // Apparently you can't store sorted orders with this stupid library
      // So store and stash the sorted keys
      const sortedIds = response.data.data.map((record) => parseInt(record.id));
      const normalizedData = normalize(response.data);

      Object.assign(normalizedData, { meta: { ids: sortedIds } });
      setHistory(normalizedData);
    });
  }

  React.useEffect(() => {
    if (shouldFetch) {
      fetchData();
    } else {
      setHistory({ meta: { ids: [] }});
    }
  }, []);

  return <HistoryList history={history} />;
};

export default HistoryContainer;
