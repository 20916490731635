import React, { useState } from 'react';
import { axiosInstance } from '@/helpers/api_helper';
import normalize from 'json-api-normalizer';
import ReactDOM from 'react-dom';

const SKELETON_DISPLAY_TIME = 1200;
const CORRECT_INCORRECT_DISPLAY_TIME = 400;

const useDataFetchOnLoad = (requestUrl) => {
  const [normalizedData, setNormalizedData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [showSkeleton, setShowSkeleton] = useState(true);

  function fetchData(skipTimeout) {
    const request = axiosInstance.get(requestUrl);

    // Don't refetch the data too soon
    const timeout = new Promise((resolve) => {
      setTimeout(resolve, skipTimeout ? 0 : CORRECT_INCORRECT_DISPLAY_TIME);
    });

    setLoading(true);

    return Promise.all([request, timeout]).then((values) => {
      const response = values[0];
      const newData = normalize(response.data);

      ReactDOM.unstable_batchedUpdates(() => {
        setLoading(false);
        setLoaded(true);
        setNormalizedData({ ...newData });
      });
    });
  }

  React.useEffect(() => {
    // Load the initiatl data and show a skeleton
    const timeout = new Promise((resolve, reject) => {
      setTimeout(resolve, SKELETON_DISPLAY_TIME);
    });

    const request = fetchData(true);

    Promise.all([request, timeout]).then(() => {
      setShowSkeleton(false);
    })
  }, []);


  return { fetchData, normalizedData, loaded, loading, showSkeleton };
}

export default useDataFetchOnLoad;
