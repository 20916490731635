import React from 'react';
import styled from 'styled-components';

import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { StaticAppWrapper } from '@/components/Static/Common/StaticLayout';
import StatsList from './StatsList';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import ListIcon from '@mui/icons-material/List';

const DashboardBody = styled(StaticAppWrapper)`
  ${({ theme }) => `
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-top: 10px;

    ${theme.breakpoints.up('sm')} {
      padding-top: 10px;
    }

    p {
      text-align: center;
    }
  `}
`;

const StyledButton = styled(Button)`
  margin: 45px 0;
`;

const SignUpAlert = styled(Alert)`
  margin-top: 45px;
`;

const emptyStats = { totalSeen: 0 };
const emptyStatsList = {
  paraPor: emptyStats,
  subjunctiveIndicative: emptyStats,
  verbTakesPreposition: emptyStats,
  verbCloze: emptyStats,
  directIndirectObject: emptyStats,
};

const UnAuthenticatedDashboard = () => {
  const navigate = useNavigate();

  const handleSignUpClick = () => navigate('/sign-up');

  return (
    <DashboardBody>
      <StatsList disabled stats={emptyStatsList} />

      <SignUpAlert severity="info" data-cy="need-to-be-logged-in">
        You need to be signed in to view history and bookmarks.
      </SignUpAlert>

      <StyledButton
        data-cy="call-to-action"
        variant="contained"
        color="primary"
        onClick={handleSignUpClick}
      >
        Sign Up Now!
      </StyledButton>

      <Tabs
        value="history"
        indicatorColor="primary"
        textColor="primary"
        centered
        data-cy="tabs"
      >
        <Tab disabled value="history" label="History" icon={<ListIcon />} iconPosition="end" data-cy="history-tab" />
        <Tab disabled value="bookmarks" label="Bookmarks" icon={<BookmarkBorderIcon />} iconPosition="end" data-cy="bookmarks-tab" />
      </Tabs>
    </DashboardBody>
  );
};

export default UnAuthenticatedDashboard;
