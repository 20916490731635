import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import CardHeader from './CardHeader';

import CardActionBar from './CardActionBar';
import StatusIcons from './StatusIcons';
import FlagIcon from '@mui/icons-material/Flag';

import FormDialog from './FormDialog';
import { AuthContext } from '@/containers/AuthProvider';

const StyledCard = styled(Card)`
  position: relative;
  max-width: 680px;
  margin: 0 auto;
`;

const CardBody = styled.div`
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  ${({ theme }) => `
    ${theme.breakpoints.down('xs')} {
      min-height: 350px;
      padding: 0 10px;
    }
  `}
`;

const StyledGrid = styled(Grid)`
  padding: 4px 12px;
  margin-bottom: 16px;
`;

const Report = styled.div`
  position: absolute;
  text-decoration: underline;
  bottom: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;

  svg {
    height: .8em;
  }

  ${({ theme }) => `
    ${theme.breakpoints.down('sm')} and (orientation: landscape) {
      bottom: 2px;
    }
  `}
`;

const PracticeCard = ({
  cardProps,
  children,
  id,
  initialLoadPending,
  question,
  sentence,
  showFailure,
  showSuccess,
  type,
  loading,
  titleNode
}) => {
  const [reportModalOpen, setReportModalOpen] = useState(false);
  const { isAuthenticated } = React.useContext(AuthContext);

  const handleClickOpen = () => {
    setReportModalOpen(true);
  };

  const handleClose = () => {
    setReportModalOpen(false);
  };

  return (
    <>
      <StyledGrid item xs={12} sm={10} md={10} lg={7}>
        <StyledCard data-cy="card" {...cardProps}>
          <StatusIcons showFailure={showFailure} showSuccess={showSuccess} />
            <CardHeader
              questionId={id}
              questionType={type}
              bookmarkId={question.bookmarkId}
              titleNode={titleNode}
              loading={loading || initialLoadPending}
            />
          <CardBody>
            {children}
          </CardBody>
          <CardActionBar id={id} sentence={sentence} loading={loading} initialLoadPending={initialLoadPending} />
        </StyledCard>
      </StyledGrid>
      {isAuthenticated && (
        <>
          <FormDialog open={reportModalOpen} type={type} question={question} handleClose={handleClose} />
          <Report data-cy="flag-button" onClick={handleClickOpen}>Report an issue with this card <FlagIcon /></Report>
        </>
      )}
    </>
  );
};

PracticeCard.propTypes = {
  cardProps: PropTypes.object,
  children: PropTypes.node,
  id: PropTypes.number,
  initialLoadPending: PropTypes.bool,
  question: PropTypes.object,
  sentence: PropTypes.object,
  showFailure: PropTypes.bool,
  showSuccess: PropTypes.bool,
  type: PropTypes.string.isRequired,
  titleNode: PropTypes.node,
};

export default PracticeCard;
