import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import Typography from '@mui/material/Typography';

const Message = styled(Typography)`
  padding-top: 12px;
  color: crimson;
`;

const ErrorMessage = ({ message, ...rest }) => {
  if (message) {
    return (
      <Message data-cy="errors" component="h4" {...rest}>
        {message}
      </Message>
    );
  }

  return null;
};

ErrorMessage.propTypes = {
  message: PropTypes.string
};

export default ErrorMessage;
