import React from 'react';
import styled from 'styled-components';

import Grid from '@mui/material/Grid';

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import Statistic from './Statistic';

const ResetLink = styled.div`
  ${({ theme }) => `
    text-decoration: underline;
    align-self: flex-end;
    cursor: pointer;
    font-size: 14px;

    ${theme.breakpoints.down('sm')} {
      text-align: right;
    }
  `}
`;

const StatsList = ({ disabled, stats, resetData }) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Grid container columns={15} spacing={4} justifyContent="center" alignItems="center">
      <Statistic disabled={disabled} name="Para/Por" data={stats ? stats.paraPor : null} />
      <Statistic disabled={disabled} name="Subjunctive/Indicative" data={stats ? stats.subjunctiveIndicative : null} />
      <Statistic disabled={disabled} name="Verbs with Prepositions" data={stats ? stats.verbTakesPreposition : null} />
      <Statistic disabled={disabled} name="Direct/Indirect Object" data={stats ? stats.directIndirectObject : null} />
      <Statistic disabled={disabled} name="Verb Cloze" data={stats ? stats.verbCloze : null} />
      {mobile && resetData && (
        <Grid item xs={15}>
          <ResetLink onClick={resetData}>Reset Statistics</ResetLink>
        </Grid>
      )}
    </Grid>
  );
};

export default StatsList;
