export const PARA_POR_MAP = {
  para_expression: 'Expression with para.',
  por_expression: 'Expression with por.',
  verb_that_takes_por: 'Verb that takes por as a preposition.',
  passive_construction: 'Passive Construction; ex "El libro fue escrito por el autor."',
  duration: 'Duration.',
  multiplication_division: 'Multiplication or division.',
  deadline: 'Deadline.',
  exchange: 'Exchange; ex "Voy a cambiarlo por el otro."',
  destination: 'Destination.',
  contrast: 'Contrast to the expected.',
  goal_in_order_to: 'Goal or purpose of the action; in order to.',
  method_of_travel: 'Method of travel.',
  method_of_communication: 'Method of communication',
  movement_along_around_through_by: 'Meaning movement along, around, through or by.',
  purpose_of_object: 'Purpose of object.',
  recipient: 'Recipient.',
  reason_because_of: 'The reason for the action; because of.',
  directed_to: 'Destination of the action, for the benefit of.',
  opinion: 'Opinion.',
  per_distribution: 'Distribution; per.',
  velocity: 'Velocity.',
  on_behalf_of: 'For meaning on behalf of.',
  by: 'By.',
  recipient_of_emotion: 'Recipient of emotion.',
  support_in_favor_of: 'Support for; to be in favor of.',
  ability: 'To have an ability for something.',
  for_the_sake_of: 'Meaning for the sake of.',
  time_of_day: 'Expression for time of day.',
  approximate_date: 'To express an approximate date; around.',
  specific_date: 'A specific date.',
  preposition_with_motion: 'Indicating movement with a preposition.',
  vague_location: 'Expression for a vague location; around here, over there.',
  gratitude: 'To express gratitude or congratulate.',
  frequency: 'Expression for the frequency of an action; por semana.',
  out_of_owing_to: 'Reason; out of or owing to.',
  errand: 'The reason for an errand, occuring with a verb of movement.',
  por_infinitive_uncompleted: 'An action yet to be completed; ex "cosas por hacer".',
  price: 'Price.',
  be_employed_by: 'To work for someone.',
  motive_or_cause_of_action: 'Motive or cause of an action.',
  qualifies_a_quality: 'To qualify a quality.',
  por_method: 'Method; meaning how something was done.',
  condition: 'Condition.',
  specific_part: 'To specify a specific part of an object; by.',
  purpose: 'The purpose for which something was done.',
};

export const SUBJUNCTIVE_MAP = {
  ojala: 'Ojalá.',
  doubt: 'Doubt.',
  recommendation_or_request: 'Recommendation or request.',
  impersonal_expression: 'Impersonal expression.',
  emotion: 'Emotion.',
  wish_desire: 'Wish or desire.',
  hypothetical_construction: 'Hypothetical construction.',
  expression_of_certainty: 'Expression of certainty.',
  knowledge: 'Knowledge.',
  para_que: 'Para que.',
  quizas: 'Quizás.',
  adverbial_clause_habitual: 'Habitual action.',
  adverbial_clause_anticipated: 'Action that takes place dependent on another action.',
  adverbial_clause_completed: 'Action dependent on another that has already taken place.',
  adverbial_clause_fact: 'Independent adverbial clause.',
  adverbial_clause_unknown: 'Adverbial clause with uncertainty.',
  adjectival_clause_definite: 'Description of a specific person/thing.',
  adjectival_clause_indefinite: 'Description of a person/thing that may not exist.',
  adjectival_clause_does_not_exist: 'Description of a person/thing that does not exist.',
  uncertainty: 'Uncertainty.',
  fact: 'Fact.',
  command: 'Command.',
  speech: 'Speech.',
  antes_de_que: 'Antes de que.',
  negation_of_expression_of_certainty: 'Negation of expression of certainty.',
  perception: 'Perception.',
  expression_of_uncertainty: 'Expression of uncertainty.',
  a_menos_que: 'A menos que.',
  event: 'Event.',
  sin_que: 'Sin que.',
  en_caso_de_que: 'En caso de que.',
  como_si: 'Como si.',
  negation_of_thought_percention: 'Negation of thought/perception.',
  si_clause_present: 'Si expression in the present.',
  mientras_simultaneous: 'Mientras indicating two actions happening at the same time.',
  whenever_whereever_whatever: 'Indicating whenever/whereever/whatever.',
  lo_que_relative_pronoun: 'Lo que relative pronoun.',
  que_te: 'Que... expression, expressing emotion.',
};

export const VERB_TAKES_PREPOSITION_MAP = {
  aburrirse_con_noun_verb: 'Aburrirse con (to get bored of)',
  abstenerse_de_hacer_algo: 'Abstenerse de hacer algo (to abstain from doing something)',
  abusar_de_noun: 'Abusar de + noun (to abuse/take advantage of)',
  acabar_con_noun: 'Acabar con + noun (to finish with)',
  acabar_de_inf: 'Acabar de + inf (to have just done)',
  acordarse_de_noun_inf: 'Acordarse de (to remember)',
  acostumbrarse_a_noun_inf: 'Acostumbrarse a (to become accustomed to)',
  aprender_a_inf: 'Aprender a + inf (to learn how to)',
  asistir_a_noun: 'Asistir a + noun (to attend)',
  aspirar_a_noun_inf: 'Aspirar a (to aspire to)',
  atreverse_a_inf: 'Atreverse a + inf (to dare to)',
  ayudar_a_inf: 'Ayudar a + inf (to help to)',
  burlarse_de_noun: 'Burlarse de + noun (to make fun of)',
  cambiar_de_noun: 'Cambiar de + noun (to change)',
  cansarse_de_noun_inf: 'Cansarse de (to get tired of)',
  casarse_con_noun: 'Casarse con + noun (to marry someone)',
  comenzar_a_inf: 'Comenzar a + inf (to begin to)',
  condenar_a_noun_inf: 'Condenar a (to condemn to)',
  confiar_en_noun: 'Confiar en + noun (to trust in)',
  confundir_con_noun: 'Confundir con + noun (to mistake for something)',
  consistir_en_noun: 'Consistir en + noun (to consist of)',
  contar_con_noun: 'Contar con + noun (to count on)',
  convertirse_en_noun: 'Convertirse en + noun (to become)',
  decidirse_a_noun_inf: 'Decidirse a (to decide to)',
  dedicarse_a_noun_inf: 'Dedicarse a (to dedicate to)',
  depender_de_noun: 'Depender de (to depend on)',
  despedirse_de_noun: 'Despedirse de + noun (to say goodbye to)',
  disculparse_de_noun: 'Disculparse de + noun (to apologize for)',
  disfrutar_de_noun: 'Disfrutar de + noun (to enjoy)',
  empezar_a_inf: 'Empezar a + inf (to start to)',
  ensenar_a_inf: 'Enseñar a + inf (to teach or show how to)',
  insistir_en_noun_inf: 'Insistir en (to insist on)',
  invitar_a_noun_inf: 'Invitar a (to invite to)',
  llegar_a_inf: 'Llegar a + inf (to succeed in)',
  mandar_a_inf: 'Mandar a + inf (to send to)',
  montar_en_noun: 'Montar en (to ride)',
  morir_de_noun: 'Morir de + noun (to die of)',
  negar_a_inf: 'Negar a + inf (to refuse to)',
  obligar_a_inf: 'Obligar a + inf (to obligate to)',
  ocuparse_de_noun: 'Ocuparse de + noun (to be busy with/take care of)',
  oler_a_noun: 'Oler a + noun (to smell like)',
  olvidarse_de_noun_inf: 'Olvidarse de (to forget)',
  parar_de_inf: 'Parar de + inf (to stop)',
  pensar_de_noun_inf: 'Pensar de (to have an opinion about)',
  pensar_en_noun_inf: 'Pensar en (to think about)',
  ponerse_a_inf: 'Ponerse a + inf (to begin to)',
  regresar_a_noun_inf: 'Regresar a (to go back to)',
  saber_a_noun: 'Saber a + noun (to taste like)',
  salir_a_inf: 'Salir a + inf (to go out to)',
  servir_de_noun: 'Servir de + noun (to act as or be useful for)',
  sonar_a_noun: 'Sonar a + noun (to sound like)',
  sonar_con_noun_inf: 'Soñar con (to dream about)',
  tardar_en_noun_inf: 'Tarder en (to delay in)',
  temer_a_noun: 'Temer a + noun (to be afraid of)',
  termindar_de_inf: 'Terminar de + inf (to finish)',
  tratar_de_inf: 'Tratar de + inf (to try to)',
  venir_a_inf: 'Venir a + inf (to come to)',
  volver_a_inf: 'Volver a + inf (to do __ again)',
};

export const DIRECT_INDIRECT_OBJECT_MAP = {
  hacerlo: 'https://www.elcastellano.org/mar%C3%ADa-394',
  hacerla: 'https://www.elcastellano.org/mar%C3%ADa-394',
  creerlo: 'https://twitter.com/raeinforma/status/1045228411210403840?',
  creerle: 'https://www.espanolavanzado.com/28-uso-de-palabras/638-creerle-o-creerlo',
};
