import React, { useState } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { axiosInstance } from '@/helpers/api_helper';

const StyledSelect = styled(Select)`
  min-width: 225px;
`;

const StyledContent = styled(DialogContent)`
  padding: 0px 24px 32px;

  ${({ theme }) => `
    ${theme.breakpoints.up('md')} {
      min-width: 600px;
    }
  `}
`;

const StyledDialog = styled(Dialog)`
  ${({ theme }) => `
  `}
`;

const StyledFormControl = styled(FormControl)`
  padding-bottom: 8px;
`;

const StyledLabel = styled(InputLabel)`
  transform: translate(0px, 16px) scale(1);
  -webkit-transform: translate(0px, 16px) scale(1);
  -moz-transform: translate(0px, 16px) scale(1);
  -ms-transform: translate(0px, 16px) scale(1);
`;

const FormDialog = ({
  handleClose,
  question,
  open,
  type
}) => {
  const sendEmail = (e) => {
    const params = {
      question_id: question.id,
      question_type: type,
      error_type: formState.select,
      other: formState.other
    };

    axiosInstance.post(`/api/v1/flag`, params)
      .then((response) => {
        resetStateAndClose(e);
      });
  }

  const resetStateAndClose = (e) => {
    setFormState({ select: '', other: '' });
    handleClose(e);
  }

  const [formState, setFormState] = useState({
    select: '',
    other: ''
  });

  const handleSelect = (e) => {
    setFormState({ ...formState, select: e.target.value });
  };

  const handleText = (e) => {
    setFormState({ ...formState, other: e.target.value });
  };

  return (
    <StyledDialog open={open} onClose={resetStateAndClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Report Error</DialogTitle>
      <StyledContent>
        <StyledFormControl>
          <StyledLabel id="problem-label">Problem:</StyledLabel>
          <StyledSelect
            variant="standard"
            id="error"
            name="error"
            color="secondary"
            autoComplete='off'
            data-cy="error-select"
            onChange={handleSelect}
            value={formState.select}
            fullWidth
          >
            <MenuItem value="answer" key="answer">Answer is wrong</MenuItem>
            <MenuItem value="options" key="options">Options are wrong</MenuItem>
            <MenuItem value="translation" key="translation">Translation is wrong</MenuItem>
            <MenuItem value="ambiguous" key="ambiguous">Sentence is ambiguous</MenuItem>
            <MenuItem value="sentence" key="sentence">Sentence has an error</MenuItem>
          </StyledSelect>
        </StyledFormControl>
        <TextField
          id="details"
          label="Other/More Details:"
          color="secondary"
          onChange={handleText}
          value={formState.other}
          variant="standard"
          multiline
          rows={6}
          fullWidth
        />
      </StyledContent>
      <DialogActions>
        <Button onClick={resetStateAndClose} data-cy="cancel-button" color="primary">
          Cancel
        </Button>
        <Button onClick={sendEmail} data-cy="send-button" disabled={formState.select === '' && formState.other === ''} color="primary">
          Send
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

FormDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default FormDialog;
