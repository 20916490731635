import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import CardActions from '@mui/material/CardActions';
import Fade from '@mui/material/Fade';
import CircularProgress from '@mui/material/CircularProgress';

import { grey } from '@mui/material/colors';

const StyledCircularProgress = styled(CircularProgress)`
  margin-left: 8px;
`;

const StyledActions = styled(CardActions)`
  background-color: ${grey[50]};
  padding: 4px;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: ${grey[200]};
  display: flex-end;
  justify-content: space-between;
  min-height: 48px;
`;

const Attribution = styled.div`
  font-style: italic;
  padding-right: 14px;
  opacity: 0.7;
  font-size: 12px;
`;

const CardActionBar = ({
  id, initialLoadPending, sentence, loading,
}) => {
  const source = sentence && sentence.source;

  return (
    <StyledActions>
      <div>
        {loading && (
          <Fade
            in={loading}
            style={{
              transitionDelay: loading ? '800ms' : '0ms',
            }}
            unmountOnExit
          >
            <StyledCircularProgress size={20} />
          </Fade>
        )}
      </div>
      {!initialLoadPending && (
        <div>
          {source === 'Tatoeba' && (
            <Attribution>
              Source:
              {' '}
              {source}
            </Attribution>
          )}
        </div>
      )}
    </StyledActions>
  );
};

CardActionBar.propTypes = {
  sentence: PropTypes.object,
  loading: PropTypes.bool,
};

export default CardActionBar;
