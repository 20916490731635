import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';

import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';

import { ErrorMessage } from '@/components/Shared';

const StyledAvatar = styled(Avatar)`
  ${({ theme }) => `
    margin: ${theme.spacing(1)};
    backgroundColor: ${theme.palette.secondary.main};
  `}
`;

const SuccessMessage = styled(Typography)`
  padding-top: 12px;
  color: green;
`;

const StyledGrid = styled(Grid)`
  ${({ theme }) => `
    margin-top: ${theme.spacing(2)};
    padding: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;

    ${theme.breakpoints.up('md')} {
      margin-top: ${theme.spacing(8)};
    }
  `}
`;

const StyledForm = styled.form`
  ${({ theme }) => `
    width: 100%; // Fix IE 11 issue.
    margin-top: ${theme.spacing(1)};
  `}
`;

const SessionFormWrapper = ({ children, errorMessage, handleSubmit, successMessage, title }) => (
  <StyledGrid item xs={12} sm={8} md={4}>
    <StyledAvatar>
      <LockOutlinedIcon />
    </StyledAvatar>
    <Typography component="h1" variant="h5">
      {title}
    </Typography>
    <ErrorMessage message={errorMessage} component="h4" />
    {successMessage &&
      <SuccessMessage data-cy="success" component="h4">
        {successMessage}
      </SuccessMessage>
    }
    <StyledForm noValidate onSubmit={handleSubmit}>
      {children}
    </StyledForm>
  </StyledGrid>
);

SessionFormWrapper.propTypes = {
  errorMessage: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  successMessage: PropTypes.string
};

export default SessionFormWrapper;
