import Typography from '@mui/material/Typography';
import styled from 'styled-components';

export const PageTitle = styled(Typography)`
  text-align: center;
  padding-bottom: 28px;
`;

export const Section = styled.div`
  margin-bottom: 20px;
`;
