import React from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import styled from 'styled-components';
import { PageTitle, Section } from './Common';

const PageDescription = styled(Typography)`
  margin-bottom: 20px;
`;

const Privacy = () => (
  <>
    <PageTitle variant="h4">Privacy Policy</PageTitle>
    <PageDescription variant="body2">
      We have developed this Privacy Policy so that you might understand how we collect, use, communicate and disclose personal information. This privacy policy, together with our Terms and Conditions, outline the general rules and policies governing your use of our website.  By accessing or using SpanishInAMinute.com you agree to the terms outlined in this privacy policy.
    </PageDescription>
   
    <Section>
      <Typography variant="h5">Personally Identifying Information We Collect</Typography>
      <Typography variant="body2">
        <ul>
          <li>Email Address</li>
          <li>Username</li>
          <li>Encrypted password</li>
        </ul>
      </Typography>
    </Section>

    <Section>
      <Typography variant="h5">How We Collect This Data</Typography>
      <Typography variant="body2">
        Information about users is obtained through the registration process, when editing your profile, or through your activity while on the site.
      </Typography>
    </Section>

    <Section>
      <Typography variant="h5">How We Use This Information</Typography>
      <Typography variant="body2">
        We use this information to administer your account and to send emails when specifically requested (ie reset password).
        Additionally we collect and use analytic information to be able to develop and provide better experiences to our users.
      </Typography>
    </Section>

    <Section>
      <Typography variant="h5">How We Use Your Email Address</Typography>
      <Typography variant="body2">
        By submitting your email address on this app, you agree to receive emails from us.  We only send emails to people who have directly authorized us to contact them.  We do not send unsolicited commercial emails, because we hate spam as much as you do.
      </Typography>
    </Section>

    <Section>
      <Typography variant="h5">How Long Do We Keep Your Information?</Typography>
      <Typography variant="body2">
        We keep your information only so long as we need it to provide SpanishInAMinute.com to you and fulfill the purposes described in this Privacy Policy. This is also the case for anyone that we share your information with and who carries out services on our behalf. When we no longer need to use your information and there is no need for us to keep it to comply with our legal or regulatory obligations, we’ll either remove it from our systems or depersonalize it so that we can't identify you.
      </Typography>
    </Section>

    <Section>
      <Typography variant="h5">Can I Update Or Correct My Information?</Typography>
      <Typography variant="body2">
        You are free to edit your information at any time by accessing your profile.

        If at any point you would like to delete your account, or any personally identifying information, you have the right to do so by sending an email to <Link href="mailto:spanishinaminute@gmail.com">spanishinaminute@gmail.com</Link>.  After your account is deleted we will retain aggregate or anonymous data that had previously been collected.
      </Typography>
    </Section>
 
    <Section>
      <Typography variant="h5">Third Party Services</Typography>
      <Typography variant="body2">
        We will share your information with 3rd party providers when required in order to deliver our services.  These include <Link href="https://support.google.com/analytics/answer/6004245">Google Analytics</Link> for collecting analytic information, as well as <Link href="https://sendgrid.com/resource/general-data-protection-regulation-2/">SendGrid</Link> for sending emails to users.
      </Typography>
    </Section>
  
    <Section>
      <Typography variant="h5">Cookies</Typography>
      <Typography variant="body2">
        A cookie is a small piece of data stored on your computer or mobile device by your web browser.  We never place Personally Identifiable Information in Cookies.
      </Typography>
    </Section>

    <Section>
      <Typography variant="h5">How We Use Cookies</Typography>
      <Typography variant="body2">
        <ul>
          <li>
            To keep the user signed into their account
          </li>
          <li>
            To record the acceptance of this Privacy and Cookie Policy
          </li>
          <li>
            To save user preferences
          </li>
          <li>
            To collect and store analytic information with <Link href="https://support.google.com/analytics/answer/6004245">Google Analytics</Link>
          </li>
        </ul>
      </Typography>
    </Section>

    <Section>
      <Typography variant="h5">How To Opt Out Of And Delete Cookies</Typography>
      <Typography variant="body2">
        You may opt out of cookie usage on our site by directing your browser to not accept cookies.  Doing so will render some of the features of our site (specifically logging in) inoperable.   Disabling a cookie or category of cookie does not delete the cookie from your browser, you will need to do this yourself from within your browser, you should visit your browser's help menu for more information.
      </Typography>
    </Section>

    <Section>
      <Typography variant="h5">Children’s Information</Typography>
      <Typography variant="body2">
        We do not offer our services to children under the age of 13 years old, and any individual under the age of 13 years old is explicitly forbidden from using our website.  If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers.
      </Typography>
    </Section>

    <Section>
      <Typography variant="h5">Information Security</Typography>
      <Typography variant="body2">
        We protect personal information by reasonable security safeguards against loss or theft, as well as unauthorized access, disclosure, copying, use or modification.
      </Typography>
    </Section>

    <Section>
      <Typography variant="h5">Do Not Track</Typography>
      <Typography variant="body2">
        This site is not designed to respond to “do not track” signals sent by some browsers.
      </Typography>
    </Section>

    <Section>
      <Typography variant="h5">Changes To Our Privacy Policy</Typography>
      <Typography variant="body2">
        We may change our Service and policies, and we may need to make changes to this Privacy Policy so that they accurately reflect our Service and policies. Unless otherwise required by law, we will notify you (for example, through our Service) before we make changes to this Privacy Policy and give you an opportunity to review them before they go into effect. Then, if you continue to use the Service, you will be bound by the updated Privacy Policy. If you do not want to agree to this or any updated Privacy Policy, you can delete your account.
      </Typography>
    </Section>

    <Section>
      <Typography variant="h5">Your Consent</Typography>
      <Typography variant="body2">
        We've updated our Privacy Policy to provide you with complete transparency into what is being set when you visit our site and how it's being used. By using our app, registering an account, or making a purchase, you hereby consent to our Privacy Policy and agree to its terms.
      </Typography>
    </Section>

    <Section>
      <Typography variant="h5">Contact Us</Typography>
      <Typography variant="body2">
        If you have any further questions about this Privacy Policy, please reach out to us at <Link href="mailto:spanishinaminute@gmail.com">spanishinaminute@gmail.com</Link>, and we will respond to you as quickly as possible.
      </Typography>
    </Section>
  </>
);

export default Privacy;
