import React from 'react';

import GoogleIcon from '@mui/icons-material/Google';
import styled from 'styled-components';
import { SubmitButton } from '@/components/Shared';

const SubmitButtonWithoutMargin = styled(SubmitButton)`
  margin-top: 0;
`;

const StyledGoogleIcon = styled(GoogleIcon)`
  margin-right: 6px;
`;

type GoogleLinkProps = {
  disabled?: boolean,
  text: string
}

const GoogleLink = ({ disabled = false, text }: GoogleLinkProps): JSX.Element => (
  <SubmitButtonWithoutMargin disabled={disabled} href="/users/auth/google_oauth2">
    <StyledGoogleIcon />
    {text}
  </SubmitButtonWithoutMargin>
);

export default GoogleLink;
