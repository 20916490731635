import React from 'react';

import Typography from '@mui/material/Typography';

import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { PageTitle, Section } from './Common';

const Faq = () => (
  <>
    <Helmet>
      <title>SpanishInAMinute - FAQ</title>
      <meta name="description" content="Frequently Asked Questions" />
    </Helmet>

    <PageTitle variant="h4">FAQ</PageTitle>

    <Section>
      <Typography variant="h5">How can I customize what kind of content I see?</Typography>
      <Typography variant="body2">
        In your
        {' '}
        <Link to="/profile">user profile</Link>
        {' '}
        you can configure if you'd like to be presented with sentences and conjugations for usted
        and vosotros.
        <br />
        Similarly, on the
        {' '}
        <Link to="/profile/verb-tenses">verb tenses tab</Link>
        {' '}
        you can fully configure which verb tenses you are comfortable seeing.
        <br />
        <br />
        These settings apply to all content on the site; grammar questions and conjugation practice.  You will no longer see sentences which contain
        conjugations you're not ready to see yet - except in the case of conjugation practice if you choose to practice those kinds of conjugations.  For
        example, if you have chosen to only see sentences with verbs in the present in your user settings, if you then start a conjugation practice session for
        verbs in the preterite, you will be presented with sentences that contain verbs in both the present and preterite.
        <br />
        You may return to change, and customize these values at any time!
      </Typography>
    </Section>
    <Section>
      <Typography variant="h5">It says I have run out of questions!</Typography>
      <Typography variant="body2">
        When logged in, we will try not to show you the same questions multiple times in a row.
        Due to this, it's possible that you might run out of questions if we do not currently have enough meeting your
        practice criteria.  You might try reducing the specificity of your conjugation practice - for example, if you run out of
        questions practicing "irregular ir verbs in the preterite", you will have a larger pool of sentences to choose from if
        you relax the parameters to "irregular verbs in the preterite".
      </Typography>
    </Section>
  </>
);

export default Faq;
