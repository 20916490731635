export function verbFormDisplay(form, displayText) {
  if (displayText !== null) {
    switch (displayText) {
      case ('usted'):
        return 'Usted';
      case ('ella'):
        return 'Ella';
      case ('ellas'):
        return 'Ellas';
      case ('nosotras'):
        return 'Nosotras';
      case ('vosotras'):
        return 'Vosotras';
      case ('ustedes'):
        return 'Ustedes';
    }
  }

  switch (form) {
    case ('yo'):
      return 'Yo';
    case ('tu'):
      return 'Tú';
    case ('el'):
      return 'Él';
    case ('nosotros'):
      return 'Nosotros';
    case ('vosotros'):
      return 'Vosotros';
    case ('ellos'):
      return 'Ellos';
  }
}

export function tenseDisplay(tense) {
  return tense.replace('_', ' ');
}
