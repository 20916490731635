import React, { useState } from 'react';

import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { SessionFormWrapper, StyledLink, GoogleLink } from '@/components/Session/Shared';
import { SubmitButton } from '@/components/Shared';

const TermsLabel = () => (
  <div>
    I have read and agree to the
    {' '}
    <StyledLink to="/terms">Terms of Service</StyledLink>
    {' '}
    and
    {' '}
    <StyledLink to="privacy">Privacy Policy</StyledLink>
    .
  </div>
);

type SignUpFormProps = {
  handleSignUp: (email: string, password: string, passwordConfirmation: string) => void,
  errorMessage?: string
}

const SignUpForm = ({ handleSignUp, errorMessage = '' }: SignUpFormProps): JSX.Element => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [ageCheck, setAgeCheck] = useState(false);
  const [termsCheck, setTermsCheck] = useState(false);

  const handleSubmit = (e: HTMLFormElement) => {
    e.preventDefault();
    handleSignUp(email, password, passwordConfirmation);
  };

  const fieldsBlank = password === '' || passwordConfirmation === '' || email === '';

  return (
    <SessionFormWrapper errorMessage={errorMessage} title="Sign Up" handleSubmit={handleSubmit}>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email Address"
        name="email"
        autoComplete="email"
        inputProps={{ 'data-cy': 'email' }}
        autoFocus
        onChange={(e) => setEmail(e.target.value)}
      />
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="password"
        label="Password"
        type="password"
        id="password"
        autoComplete="current-password"
        inputProps={{ 'data-cy': 'password' }}
        onChange={(e) => setPassword(e.target.value)}
      />
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="password_confirmation"
        label="Password Confirmation"
        type="password"
        id="password_confirmation"
        autoComplete="current-password"
        inputProps={{ 'data-cy': 'password-confirmation' }}
        onChange={(e) => setPasswordConfirmation(e.target.value)}
      />

      <br />

      <hr width="50%" />

      <FormControlLabel
        control={(
          <Checkbox
            color="secondary"
            checked={ageCheck}
            onChange={(e) => setAgeCheck(e.target.checked)}
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        )}
        data-cy="age-check-label"
        label="I certify I am over 13 years old."
      />

      <FormControlLabel
        control={(
          <Checkbox
            checked={termsCheck}
            onChange={(e) => setTermsCheck(e.target.checked)}
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        )}
        data-cy="terms-privacy-label"
        label={<TermsLabel />}
      />

      <SubmitButton text="Sign Up" disabled={!ageCheck || !termsCheck || fieldsBlank} />
      <GoogleLink disabled={!ageCheck || !termsCheck} text="Sign up with Google" />
    </SessionFormWrapper>
  );
};

export default SignUpForm;
