import React from 'react';
import styled, { css } from 'styled-components';

import Typography from '@mui/material/Typography';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';

const StyledHeader = styled(CardHeader)`
  background-image: linear-gradient(5deg, #26c6da82, #26c6da);
  border-bottom: 1px solid rgb(219 219 219);

  opacity: .9;
  padding: 14px;

  .MuiCardHeader-title {
    font-size: 1rem;
    font-weight: bold;
  }
`;

const StyledContent = styled(CardContent)`
  padding: 22px 14px;
`;

const StyledCard = styled(Card)`
  ${({ theme }) => `
    border-radius: 2px;

    ${theme.breakpoints.up('sm')} {
      margin-bottom: 8px;
    }
  `}
`;

const StyledGrid = styled(Grid)`
  ${(props) => props.disabled && css`
    opacity: 0.5;
  `}
`;

interface DataDisplayProps {
  totalCorrect?: number
  totalSeen?: number
}

const DataDisplay = ({ totalCorrect, totalSeen }: DataDisplayProps): JSX.Element => {
  const noTotal = totalSeen === 0 || totalSeen === undefined || totalCorrect === undefined;
  const percentCorrect = noTotal ? 0 : Math.round((totalCorrect / totalSeen + Number.EPSILON) * 100);

  return (
    <Typography>
      {percentCorrect}
      % Correct
    </Typography>
  );
};

interface StatisticProps {
  name: string
  disabled?: boolean
  data?: {
    totalCorrect?: number
    totalSeen?: number
  }
}

const Statistic = ({ name, disabled = false, data }: StatisticProps): JSX.Element => {
  return (
    <StyledGrid disabled={disabled} item xs={15} sm={7} lg={3}>
      <StyledCard variant="outlined">
        <StyledHeader data-cy="statistic-header" title={name} />
        <StyledContent data-cy="statistic-percentage">
          {data ? <DataDisplay totalCorrect={data.totalCorrect} totalSeen={data.totalSeen} /> : <Skeleton animation="wave" />}
        </StyledContent>
      </StyledCard>
    </StyledGrid>
  );
};

export default Statistic;
