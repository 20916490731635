import React, { useContext, useEffect, useState } from 'react';

import { AuthContext } from '@/containers/AuthProvider';
import { axiosInstance, convertFromServerTenseArray, convertToServerTenseArray } from '@/helpers/api_helper';
import _isEmpty from 'lodash/isEmpty';
import { useNavigate } from 'react-router-dom';

import VerbClozeForm, { IFormData, AVAILABLE_TENSES, defaultState } from './VerbClozeForm';

const VerbClozeFormContainer = () => {
  const { isAuthenticated, setUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const defaultFormState = { ...defaultState };

  if (!isAuthenticated) {
    defaultFormState.tenses = AVAILABLE_TENSES;
  }

  const [formData, setFormData] = useState<IFormData>(defaultFormState);

  const handleSubmit = (evt) => {
    evt.preventDefault();

    // If the user is not logged in, redirect
    if (!isAuthenticated) {
      navigate('/verb-cloze/practice');
    } else {
      const newTenseArray = convertToServerTenseArray(formData.tenses);
      const { fetched, ...dataToSubmit } = formData;
      const data = {
        user: {
          practice_settings: {
            ...dataToSubmit,
            tenses: newTenseArray,
          },
        },
      };

      axiosInstance.patch('api/v1/users/profile', data).then(() => {
        navigate('/verb-cloze/practice');
      });
    }
  };

  const fetchData = () => {
    axiosInstance.get('api/v1/users/profile').then((response) => {
      const { attributes } = response.data.data;
      const settingsDoNotExist = _isEmpty(attributes.practice_settings);
      let newTenseArray;

      if (settingsDoNotExist) {
        // If the user doesn't have any practice setting values yet,
        // set the user's verbs as the defaults
        newTenseArray = convertFromServerTenseArray(attributes.tenses);
        setFormData({ ...formData, tenses: newTenseArray, fetched: true });
      } else {
        newTenseArray = convertFromServerTenseArray(attributes.practice_settings.tenses);
        setFormData({
          ...formData, ...attributes.practice_settings, tenses: newTenseArray, fetched: true,
        });
      }

      setUser(attributes);
    });
  };

  useEffect(() => { if (isAuthenticated) fetchData(); }, []);

  return (
    <VerbClozeForm
      isSignedIn={isAuthenticated}
      formData={formData}
      setFormData={setFormData}
      handleSubmit={handleSubmit}
    />
  );
};

export default VerbClozeFormContainer;
