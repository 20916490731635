import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import TextField from '@mui/material/TextField';

import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import { CardActionBar } from '@/components/Practice/Cards/Elements';

import { splitSentenceAtBlanks } from '@/helpers/practice_helper';

const CardBody = styled.div`
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
`;

const QuestionWrapper = styled.div`
  width: 90%;
`;

const SentenceText = styled.div`
  display: inline-block;
  margin: 0 5px 0 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
`;

const StyledInputField = styled(TextField)`
  margin: 1px 6px 0;

  input {
    padding: 1px 0 3px;
    margin: 0 6px;
    width: ${(props) => ((props.value?.length || 1) * 8) + 10}px;
    height: 1.1876em;

    &:read-only {
      color: gray;
    }
  }

  p {
    text-align: center;
  }

  &:first-child {
    margin-left: 0;
  }
`;

const ContentWrapper = styled.div`
  flex-grow: 1;
`;

const ListItem = ({
  answer, sentence, question, PreSentenceContent, PostTranslationContent, IconContent,
}) => {
  const splitSentence = splitSentenceAtBlanks({ ...question, ...sentence });

  return (
    <Grid item xs={12}>
      <Card data-cy="card">
        <CardBody>
          <ContentWrapper>
            {PreSentenceContent}
            <QuestionWrapper>
              <SentenceText>
                {splitSentence[0].length > 0 && (<span>{splitSentence[0]}</span>)}
                <StyledInputField
                  InputProps={{
                    inputProps: { 'data-cy': 'card-input' },
                  }}
                  color="secondary"
                  variant="standard"
                  disabled
                  margin="normal"
                  required
                  autoComplete="off"
                  autoFocus
                  value={answer}
                />
                <span>{splitSentence[2]}</span>
              </SentenceText>
            </QuestionWrapper>
            <div>{sentence.translation}</div>
            {PostTranslationContent}
          </ContentWrapper>

          <div>
            {IconContent}
          </div>
        </CardBody>
        {sentence.source === 'Tatoeba' && (<CardActionBar sentence={sentence} />)}
      </Card>
    </Grid>
  );
};

ListItem.defaultProps = {
  PreSentenceContent: null,
  PostSentenceContent: null,
  IconContent: null,
  answer: '',
};

ListItem.propTypes = {
  answer: PropTypes.string.isRequired,
  PreSentenceContent: PropTypes.node,
  PostTranslationContent: PropTypes.node,
  IconContent: PropTypes.node,
  sentence: PropTypes.object.isRequired,
  question: PropTypes.object.isRequired,
};

export default ListItem;
