import React from 'react';

import Button from '@mui/material/Button';

import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

import { useNavigate, useLocation } from 'react-router-dom';

const LeftNavButton = ({ loggedIn, toggleDrawer }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const showPracticeButton = !loggedIn && pathname === '/';

  const handlePracticeClick = () => navigate("/dashboard");

  return (
    <div>
      {showPracticeButton && (
        <Button
          variant="outlined"
          color="secondary"
          data-cy="practice-button"
          onClick={handlePracticeClick}
        >
          Practice
        </Button>
      )}
      {!showPracticeButton && (
        <Hidden mdUp>
          <IconButton
            data-cy="sidebar-button"
            edge="start"
            color="inherit"
            onClick={toggleDrawer}
            aria-label="Menu"
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      )}
    </div>
  );
};

export default LeftNavButton;
