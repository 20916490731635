import React from 'react';

import DropdownPractice from './DropdownPracticePage';
import { SerEstarCard } from '@/components/Practice/Cards';
import { Helmet } from 'react-helmet-async';

const SerEstarPage = () => {
  return (
    <>
      <Helmet>
        <title>SpanishInAMinute - Ser/Estar</title>
        <meta name="description" content="Ser/Estar Grammar Exercises" />
        <meta name="keywords" content="Spanish, Grammar, Ser, Estar, FlashCard" />
      </Helmet>

      <DropdownPractice
        dataUrl="/api/v1/practice/questions/ser-estar"
        CardClass={SerEstarCard} />
    </>
  )
}

export default SerEstarPage;
