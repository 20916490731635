import React from 'react'
import PropTypes from 'prop-types'

import TextField from '@mui/material/TextField';
import { SessionFormWrapper } from '@/components/Session/Shared';
import { SubmitButton } from '@/components/Shared';

import { useParams } from "react-router-dom";

const ResetPasswordForm = ({ handleChangePassword, errorMessage }) => {
  const [password, setPassword] = React.useState('');
  const [passwordConfirmation, setPasswordConfirmation] = React.useState('');
  const token = useParams()['token'];

  const handleSubmit = (evt) => {
    evt.preventDefault();
    handleChangePassword(token, password, passwordConfirmation);
  }

  return (
    <SessionFormWrapper errorMessage={errorMessage} title="Change Your Password" handleSubmit={handleSubmit}>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="password"
        label="New Password"
        type="password"
        id="password"
        autoComplete="current-password"
        inputProps={{ 'data-cy': 'password' }}
        onChange={e => setPassword(e.target.value)}
      />
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="password_confirmation"
        label="Confirm new password"
        type="password"
        id="password_confirmation"
        autoComplete="current-password"
        inputProps={{ 'data-cy': 'password-confirmation' }}
        onChange={e => setPasswordConfirmation(e.target.value)}
      />

      <SubmitButton text="Change my password" />
    </SessionFormWrapper>
  )
};

ResetPasswordForm.propTypes = {
  handleChangePassword: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
};

export default ResetPasswordForm;
