import React from 'react';
import styled from 'styled-components';

const StyledForm = styled.form`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export default StyledForm;
