import React, { useContext } from 'react';
import Grid from '@mui/material/Grid';

import { useNavigate } from 'react-router-dom';
import { axiosInstance } from '@/helpers/api_helper';
import { SnackbarContext } from '@/containers/SnackbarProvider';
import SignUpForm from './SignUpForm';

const SignUp = () => {
  const { setSnackbarOpen, setAlertState } = useContext(SnackbarContext);
  const navigate = useNavigate();

  const [error, setError] = React.useState('');

  function handleSignUp(email, password, password_confirmation) {
    return axiosInstance.post(
      '/api/v1/users',
      { user: { password, email, password_confirmation } },
    ).then((response) => {
      // TODO: CONFIRMATION
      // history.push('/confirmation-sent');
      setAlertState({ message: 'Account created.', severity: 'success' });
      setSnackbarOpen(true);
      navigate('/sign-in');
    }).catch((error) => {
      const firstFieldName = Object.keys(error.response.data.errors)[0];
      const displayedName = firstFieldName && firstFieldName.charAt(0).toUpperCase() + firstFieldName.substring(1);

      setAlertState({ message: 'Something went wrong!', severity: 'error' });
      setSnackbarOpen(true);

      setError(`${displayedName} ${error.response.data.errors[firstFieldName][0]}.`);
    });
  }

  return (
    <Grid container justifyContent="center">
      <SignUpForm handleSignUp={handleSignUp} errorMessage={error} />
    </Grid>
  );
};

export default SignUp;
